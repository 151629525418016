import { HangataCopyRangeUnitConverter } from '../../table/b/datafile/converter/hangatacopyrangeunit';
import { HangataUnitConverter } from '../../table/b/datafile/converter/hangataunit';
import { HangataCopyPageUnitConverter } from '../../table/b/datafile/converter/hangatacopypageunit';
import { GenshiIncludePagesConverter } from '../../table/b/datafile/converter/genshiincludepages';

export class TableBConverter {
  private copyRangeConv: HangataCopyRangeUnitConverter;
  private hangata: HangataUnitConverter;
  private copyPageConv: HangataCopyPageUnitConverter;
  private genshiIncludePages: GenshiIncludePagesConverter;

  public constructor() {
    this.copyRangeConv = new HangataCopyRangeUnitConverter();
    this.hangata = new HangataUnitConverter();
    this.copyPageConv = new HangataCopyPageUnitConverter();
    this.genshiIncludePages = new GenshiIncludePagesConverter();
  }

  public convert(data: any[]): Map<string, any[]> {
    const result = new Map<string, any[]>();
    for (const row of data) {
      let stack = result.get(row.type);
      if (typeof stack === 'undefined') {
        stack = [];
      }

      if (this.copyRangeConv.hasType(row.type)) {
        const unitprice = this.copyRangeConv.convert(row);
        result.set(row.type, stack.concat(unitprice));
      } else if (this.hangata.hasType(row.type)) {
        const unitprice = this.hangata.convert(row);
        result.set(row.type, stack.concat(unitprice));
      } else if (this.copyPageConv.hasType(row.type)) {
        const unitprice = this.copyPageConv.convert(row);
        result.set(row.type, stack.concat(unitprice));
      } else if (this.genshiIncludePages.hasType(row.type)) {
        const unit = this.genshiIncludePages.convert(row);
        result.set(row.type, stack.concat(unit));
      }
    }

    return result;
  }
}
