export interface Range {
  from: number;
  to: number | null;
}

export function RangeComparator(item: Range, target: number) {
  return item.from <= target && (item.to === null || item.to >= target);
}

export type UnitComparator<T, U> = (unit: T, target: U) => boolean;

export class UnitSearcher<T, U> {
  private table: T[];
  private comparator: UnitComparator<T, U>;

  public constructor(data: T[], comparator: UnitComparator<T, U>) {
    if (data.length === 0) {
      throw new Error('Data is Empty');
    }
    this.table = data;
    this.comparator = comparator;
  }

  public searchUnit(target: U): T {
    for (let row of this.table) {
      if (this.comparator(row, target)) {
        return row;
      }
    }

    throw new Error('Data is not found');
  }
}
