import * as Searcher from '../../table/a/unitpricesearcher';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { ScaningFeeCalculator } from '../../press/calcurators/scaningfee';

export class ScaningSubTotal implements ISubTotal {
  private searchers: ScaningTables;
  private formset: ScaningFormset;

  public constructor(formset: ScaningFormset, searchers: ScaningTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    if (this.formset.scanCount === null) {
      return subtotal;
    }

    const pressCalc = new ScaningFeeCalculator(this.searchers.scaningfee);
    const result = pressCalc.execute(
      this.formset.genreId,
      this.formset.scanCount
    );
    subtotal.push({ id: 'scaning', value: result });

    return subtotal;
  }
}

export interface ScaningTables {
  [key: string]: Searcher.GenreSearcher;
  scaningfee: Searcher.GenreSearcher;
}

export interface ScaningFormset {
  genreId: string;
  scanCount: number | null;
}
