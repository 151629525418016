import { CoverBase } from './coverbase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class FrontCoverPrintingPlateCalcurator extends CoverBase {
  private tokushokuFee: Searcher.HangataTypeSearcher;
  private colorFee: Searcher.HangataTypeSearcher;

  public constructor(
    tokushokuFee: Searcher.HangataTypeSearcher,
    colorFee: Searcher.HangataTypeSearcher
  ) {
    super();
    this.tokushokuFee = tokushokuFee;
    this.colorFee = colorFee;
  }

  public execute(hangataId: string, hasCover: boolean, isPP: boolean) {
    let searcher: Searcher.HangataTypeSearcher;
    if (hasCover) {
      searcher = this.colorFee;
    } else {
      searcher = this.tokushokuFee;
    }
    const unit = searcher.searchUnit(hangataId);
    const printingPlates = this.getPrintingPlates(hasCover, isPP);
    return printingPlates * unit.value;
  }
}
