import { GenreRangeSearcher } from '../table/a/unitpricesearcher';
import { Calcurator } from './calcurator';
import { SubTotalResult } from '../subtotal/structs';
import { ISubTotal } from '../subtotal/isubtotal';

export class BasicSubTotal implements ISubTotal {
  private basicfee: GenreRangeSearcher;
  private formset: BasicfeeFormSet;

  public constructor(formset: BasicfeeFormSet, basicfee: GenreRangeSearcher) {
    this.basicfee = basicfee;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    const calc = new Calcurator(this.basicfee);
    const genreId = this.formset.genreId;
    const copies = this.formset.copies;
    const basicfee = calc.execute(genreId, copies);

    return [{ id: 'basicfee', value: basicfee }];
  }
}

export interface BasicfeeFormSet {
  genreId: string;
  copies: number;
}
