import { GenreSearcher } from '../table/a/unitpricesearcher';
import { PromotionSearcher } from '../table/e/unitpricesearcher';
import * as Table from '../table/a/structs';

export class DistributionCalcurator {
  private basicFee: GenreSearcher;
  private cosignmentFee: GenreSearcher;
  private salesFee: GenreSearcher;
  private amazonFee: GenreSearcher;

  public constructor(
    basicFee: GenreSearcher,
    cosignmentFee: GenreSearcher,
    salesFee: GenreSearcher,
    amazonFee: GenreSearcher
  ) {
    this.basicFee = basicFee;
    this.cosignmentFee = cosignmentFee;
    this.salesFee = salesFee;
    this.amazonFee = amazonFee;
  }

  public execute(genreId: string, plan: Table.DistributionPlan): number {
    let total = 0;
    let basicUnit = this.basicFee.searchUnit(genreId);
    total += basicUnit.value;

    if (plan === Table.DistributionPlan.Amazon) {
      const amazon = this.amazonFee.searchUnit(genreId);
      total += amazon.value;
    }

    if (
      plan === Table.DistributionPlan.CosignmentBookStore ||
      plan === Table.DistributionPlan.CosignmentWithSales
    ) {
      const consignmentUnit = this.cosignmentFee.searchUnit(genreId);
      total += consignmentUnit.value;
    }

    if (plan === Table.DistributionPlan.CosignmentWithSales) {
      const sales = this.salesFee.searchUnit(genreId);
      total += sales.value;
    }

    return total;
  }
}

export class PromotionCalcurator {
  private searcher: PromotionSearcher;

  public constructor(promotionFee: PromotionSearcher) {
    this.searcher = promotionFee;
  }

  public execute(promotionId: string): number {
    let unit = this.searcher.searchUnit(promotionId);
    return unit.value;
  }
}
