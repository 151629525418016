import * as Searcher from '../table/a/unitpricesearcher';
import * as Calc from './calcurator';
import * as Table from '../table/a/structs';
import { SubTotalResult } from '../subtotal/structs';
import { ISubTotal } from '../subtotal/isubtotal';

export class PrepressSubTotal implements ISubTotal {
  private searchers: PrepressTables;
  private formset: PrepressFormSet;

  public constructor(formset: PrepressFormSet, searchers: PrepressTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    let result: number;
    let grade: Table.GradeType | null;
    grade = this.formset.planingGrade;
    if (grade !== null) {
      result = this.gradeTypePageCalc(this.searchers.planning, grade);
      subtotal.push({ id: 'planning', value: result });
    }

    grade = this.formset.editingGrade;
    if (grade !== null) {
      result = this.gradeTypePageCalc(this.searchers.editing, grade);
      subtotal.push({ id: 'editing', value: result });
    }

    grade = this.formset.proofreadingGrade;
    if (grade !== null) {
      result = this.gradeTypePageCalc(this.searchers.proofreading, grade);
      result = result * this.formset.proofreadingCount;
      subtotal.push({ id: 'proofreading', value: result });
    }

    if (this.formset.isWriting) {
      result = this.genreTypePageCalc(this.searchers.writing);
      subtotal.push({ id: 'writing', value: result });
    }

    if (this.formset.isRewriting) {
      result = this.genreTypePageCalc(this.searchers.rewriting);
      subtotal.push({ id: 'rewriting', value: result });
    }

    grade = this.formset.coverGrade;
    if (grade !== null) {
      result = this.gradeTypeCalc(this.searchers.coverdesign, grade);
      subtotal.push({ id: 'coverdesign', value: result });
    }

    grade = this.formset.frontcoverGrade;
    result = this.gradeTypeCalc(this.searchers.frontcoverdesign, grade);
    subtotal.push({ id: 'frontcoverdesign', value: result });

    if (this.formset.hasMikaeshi) {
      result = this.genreTypeCalc(this.searchers.mikaeshidesign);
      subtotal.push({ id: 'mikaeshidesign', value: result });
    }

    if (this.formset.hasCoverBelt) {
      result = this.genreTypeCalc(this.searchers.coverbeltdesign);
      subtotal.push({ id: 'coverbeltdesign', value: result });
    }

    result = this.genreTypePageCalc(this.searchers.dtp);
    subtotal.push({ id: 'dtp', value: result });

    return subtotal;
  }

  private gradeTypeCalc(
    searcher: Searcher.GradeTypeSearcher,
    grade: Table.GradeType
  ): number {
    const calc = new Calc.GradeTypeCalcurator(searcher);
    const result = calc.execute(this.formset.genreId, grade);

    return result;
  }

  private gradeTypePageCalc(
    searcher: Searcher.GradeTypeSearcher,
    grade: Table.GradeType
  ): number {
    const calc = new Calc.GradeTypePageCalcurator(searcher);
    const result = calc.execute(
      this.formset.genreId,
      this.formset.pages,
      grade
    );

    return result;
  }

  private genreTypeCalc(searcher: Searcher.GenreSearcher): number {
    const calc = new Calc.GenreUnitPriceCalculator(searcher);
    const result = calc.execute(this.formset.genreId);
    return result;
  }

  private genreTypePageCalc(searcher: Searcher.GenreSearcher): number {
    const calc = new Calc.GenreUnitPricePageCalculator(searcher);
    const result = calc.execute(this.formset.genreId, this.formset.pages);
    return result;
  }
}

export interface PrepressTables {
  [key: string]: Searcher.GradeTypeSearcher | Searcher.GenreSearcher;
  planning: Searcher.GradeTypeSearcher;
  editing: Searcher.GradeTypeSearcher;
  proofreading: Searcher.GradeTypeSearcher;
  writing: Searcher.GenreSearcher;
  rewriting: Searcher.GenreSearcher;
  coverdesign: Searcher.GradeTypeSearcher;
  frontcoverdesign: Searcher.GradeTypeSearcher;
  mikaeshidesign: Searcher.GenreSearcher;
  coverbeltdesign: Searcher.GenreSearcher;
  dtp: Searcher.GenreSearcher;
}

export interface PrepressFormSet {
  [key: string]: Table.GradeType | string | number | boolean | null;
  genreId: string;
  pages: number;
  planingGrade: Table.GradeType | null;
  editingGrade: Table.GradeType | null;
  proofreadingGrade: Table.GradeType | null;
  proofreadingCount: number;
  isWriting: boolean;
  isRewriting: boolean;
  coverGrade: Table.GradeType | null;
  frontcoverGrade: Table.GradeType;
  hasMikaeshi: boolean;
  hasCoverBelt: boolean;
}
