export class Container {
  private factory: Map<Symbol, ServiceProvider>;
  private singletonKey: Set<Symbol>;
  private cache: Map<Symbol, any>;

  public constructor() {
    this.factory = new Map<Symbol, ServiceProvider>();
    this.singletonKey = new Set<Symbol>();
    this.cache = new Map<Symbol, any>();
  }

  public make(id: Symbol): any {
    let result: object | undefined = undefined;

    const isSingleton = this.singletonKey.has(id);
    if (isSingleton && this.cache.has(id)) {
      result = this.cache.get(id);
    } else {
      const handler = this.factory.get(id);
      if (handler !== undefined) {
        const instance = handler(this);
        if (isSingleton) {
          this.cache.set(id, instance);
        }
        result = instance;
      }
    }
    if (result === undefined) {
      throw new Error('Not found: ' + id.toString());
    }

    return result;
  }

  public instance(id: Symbol, provider: ServiceProvider): Container {
    this.factory.set(id, provider);
    return this;
  }

  public singleton(id: Symbol, provider: ServiceProvider): Container {
    this.singletonKey.add(id);
    this.factory.set(id, provider);
    return this;
  }

  public has(id: Symbol): boolean {
    return this.factory.has(id);
  }
}

export type ServiceProvider = (container: Container) => any;
