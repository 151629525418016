import * as Searcher from '../../../table/b/unitpricesearcher';

export class HakuoshiProcessedFeeCalculator {
  private printingPlate: Searcher.HangataTypeSearcher;
  private press: Searcher.HangataRangeSearcher;

  public constructor(
    printingPlate: Searcher.HangataTypeSearcher,
    press: Searcher.HangataRangeSearcher
  ) {
    this.printingPlate = printingPlate;
    this.press = press;
  }

  public execute(hangataId: string, copies: number): number {
    let plateUnit = this.printingPlate.searchUnit(hangataId);
    let rangeTarget: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };
    let pressUnit = this.press.searchUnit(rangeTarget);

    return plateUnit.value + pressUnit.value;
  }
}
