import { IFormsetConverter } from './iformset';
import { BookBindingFormSet } from '../../press/subtotal/bookbinding';
import * as InputForm from '../structs';
import * as Table from '../../table/b/structs';

export class PressBookBingingFormsetConverter
  implements IFormsetConverter<BookBindingFormSet> {
  public convert(input: InputForm.InputParams): BookBindingFormSet {
    let result: BookBindingFormSet = {
      hangataId: input.hangataId,
      genreId: input.genreId,
      bindingType: this.getBingingType(input.bookbindingId),
      copies: input.copies,
      pages: input.pageCount,
    };

    return result;
  }

  public getBingingType(val: string): Table.BindingType {
    if (val === InputForm.BookbindingId.Hard) {
      return Table.BindingType.hard;
    }
    return Table.BindingType.soft;
  }
}
