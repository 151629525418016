import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';

export function calc_papers(
  copies: number,
  includePages: number,
  cuttingConvertion: number
): number {
  if (includePages < 1 || copies < 1 || cuttingConvertion < 1) {
    return 0;
  }
  return Math.ceil(copies / includePages / cuttingConvertion);
}

export function calc_spare_papers(numOfColors: number): number {
  let coefficient: number;
  coefficient = 100 * numOfColors;

  return coefficient;
}

// 表紙など1ページもの
export class PaperCalcurator {
  private feeSearcher: PaperInfoSearcher;

  public constructor(paperSearcher: PaperInfoSearcher) {
    this.feeSearcher = paperSearcher;
  }

  public execute(
    paperId: number,
    numOfColors: number,
    copies: number,
    includePages: number,
    cuttingConvertion: number
  ): number {
    const unit = this.feeSearcher.searchUnit(paperId);

    let papers = calc_papers(copies, includePages, cuttingConvertion);
    let sparePapers = calc_spare_papers(numOfColors);
    let totalPapers = papers + sparePapers;
    let paperUnits = Math.ceil(totalPapers / unit.sheets);

    return paperUnits * unit.value;
  }
}
