import * as BSearcher from '../../table/b/unitpricesearcher';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { HakuoshiProcessedFeeCalculator } from '../../press/calcurators/hakuoshi/processedfee';

export class HakuoshiSubTotal implements ISubTotal {
  private searchers: HakuoshiTables;
  private formset: HakuoshiFormSet;

  public constructor(formset: HakuoshiFormSet, searchers: HakuoshiTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    if (this.formset.isHakuoshi) {
      const pressCalc = new HakuoshiProcessedFeeCalculator(
        this.searchers.hakuoshiPrintingPlate,
        this.searchers.hakuoshiPress
      );
      let result = pressCalc.execute(
        this.formset.hangataId,
        this.formset.copies
      );
      subtotal.push({ id: 'hakuoshi', value: result });
    }

    return subtotal;
  }
}

export interface HakuoshiTables {
  [key: string]: BSearcher.HangataTypeSearcher | BSearcher.HangataRangeSearcher;
  hakuoshiPrintingPlate: BSearcher.HangataTypeSearcher;
  hakuoshiPress: BSearcher.HangataRangeSearcher;
}

export interface HakuoshiFormSet {
  hangataId: string;
  copies: number;
  isHakuoshi: boolean;
}
