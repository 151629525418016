import { IFormsetConverter } from './iformset';
import { MikaeshiFormSet } from '../../press/subtotal/mikaeshi';
import * as InputForm from '../structs';
import { GenreID } from '../../table/a/structs';
import { HangataGenshiIncludeSearcher } from '../../table/b/hangatagenshiincludesearcher';
import { GenshiTypeParser } from '../../table/parser/genshitype';
import { GenshiInclude, GenshiType } from '../../table/b/structs';

export class PressMikaeshiFormsetConverter
  implements IFormsetConverter<MikaeshiFormSet> {
  private genshiIncludeSearcher: HangataGenshiIncludeSearcher;

  public constructor(genshiIncludeSearcher: HangataGenshiIncludeSearcher) {
    this.genshiIncludeSearcher = genshiIncludeSearcher;
  }

  public convert(input: InputForm.InputParams): MikaeshiFormSet {
    const mikaeshiPaperId = input.mikaeshiPaperId;
    const hasMikaeshi = input.hasMikaeshi;
    const isPictureBook = input.genreId === GenreID.PictureBook;

    let mikaeshiCoverIncludePages: GenshiInclude = {
      genshiId: '',
      includePages: 0,
    };

    if (hasMikaeshi) {
      mikaeshiCoverIncludePages = this.genshiIncludeSearcher.searchPageInclude(
        mikaeshiPaperId,
        input.hangataId
      );
    }

    let genshiType: GenshiType = {
      youshiSize: '',
      cuttingConvertion: 0,
    };

    if (hasMikaeshi) {
      const parser = new GenshiTypeParser();
      genshiType = parser.parse(mikaeshiCoverIncludePages.genshiId);
    }

    let result: MikaeshiFormSet = {
      hangataId: input.hangataId,
      copies: input.copies,
      hasMikaeshi: hasMikaeshi,
      isPictureBook: isPictureBook,
      mikaeshiPaperId: mikaeshiPaperId,
      mikaeshiNumOfColors: 1,
      mikaeshiIncludePages: mikaeshiCoverIncludePages.includePages,
      mikaeshiCuttingConvertion: genshiType.cuttingConvertion,
    };

    return result;
  }
}
