import { IFormsetConverter } from './iformset';
import { BodyFormSet } from '../../press/subtotal/body';
import * as InputForm from '../structs';
import { HangataGenshiIncludeSearcher } from '../../table/b/hangatagenshiincludesearcher';
import { GenshiTypeParser } from '../../table/parser/genshitype';

export class PressBodyFormsetConverter
  implements IFormsetConverter<BodyFormSet> {
  private genshiIncludeSearcher: HangataGenshiIncludeSearcher;

  public constructor(genshiIncludeSearcher: HangataGenshiIncludeSearcher) {
    this.genshiIncludeSearcher = genshiIncludeSearcher;
  }

  public convert(input: InputForm.InputParams): BodyFormSet {
    const isTextColor = input.textcolor === InputForm.TextColor.Color;
    const bodyIncludePages = this.genshiIncludeSearcher.searchPageInclude(
      input.bodyPaperId,
      input.hangataId
    );

    const parser = new GenshiTypeParser();
    const genshiType = parser.parse(bodyIncludePages.genshiId);

    let result: BodyFormSet = {
      hangataId: input.hangataId,
      copies: input.copies,
      isBodyColor: isTextColor,
      bodyPages: input.pageCount,
      bodyPaperId: input.bodyPaperId,
      bodyNumOfColors: isTextColor ? 4 : 1,
      bodyIncludePages: bodyIncludePages.includePages,
      bodyCuttingConvertion: genshiType.cuttingConvertion,
    };

    return result;
  }
}
