import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import { PaperInfoSearcher } from '../../../table/master/unitpricesearcher/paperinfo';
import { PaperInfoComparator } from '../../../table/master/comparator/paperinfocomparator';
import * as Table from '../../../table/master/structs';

export class PaperInfo implements IRegister {
  public register(container: Container) {
    container.singleton(TYPES.Searcher.master.paperinfo, (c: Container) => {
      const unitprices = c.make(
        TYPES.UnitPrice.table.master.paper
      ) as Table.PaperInfo[];
      return new PaperInfoSearcher(unitprices, PaperInfoComparator);
    });
  }
}
