import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';

export class HangataUnitConverter
  implements DataFileConverter<Table.HangataTypeUnitPrice> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.HakuoshiPrintingPlate,
    FileData.DataType.MikaeshiEhonPrintingPlate,
    FileData.DataType.MikaeshiProofReading,
    FileData.DataType.BodyPrintingPlate,
    FileData.DataType.BodyProofReadingMono,
    FileData.DataType.BodyProofReadingColor,
    FileData.DataType.CoverPrintingPlate,
    FileData.DataType.CoverPrintingPlatePP,
    FileData.DataType.CoverProofReading,
    FileData.DataType.CoverBeltPrintingPlateTokushoku,
    FileData.DataType.CoverBeltPrintingPlateColor,
    FileData.DataType.CoverBeltProofReading,
    FileData.DataType.FrontCoverPrintingPlateTokushoku,
    FileData.DataType.FrontCoverPrintingPlateColor,
    FileData.DataType.FrontCoverProofReading,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.HangataTypeUnitPrice {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (typeof parsed_data.value === 'undefined') {
      throw new Error('invalid type');
    }

    let data = parsed_data as FileData.IdFeeTable;
    let result: Table.HangataTypeUnitPrice = {
      hangataId: data.hangataId,
      value: data.value,
    };

    return result;
  }
}
