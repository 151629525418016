import { CoverBase } from './coverbase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class CoverPrintingCalcurator extends CoverBase {
  private feeSearcher: Searcher.HangataRangeSearcher;
  private ppFeeSearcher: Searcher.HangataRangeSearcher;

  public constructor(
    feeSearcher: Searcher.HangataRangeSearcher,
    ppFeeSearcher: Searcher.HangataRangeSearcher
  ) {
    super();
    this.feeSearcher = feeSearcher;
    this.ppFeeSearcher = ppFeeSearcher;
  }

  public execute(hangataId: string, isPP: boolean, copies: number) {
    let target: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };
    let unit = this.feeSearcher.searchUnit(target);
    let printingPlates = this.getPrintingPlates(isPP);
    let printingFee = printingPlates * unit.value;
    if (isPP === false) {
      return printingFee;
    }

    let ppTarget: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };
    let ppUnit = this.ppFeeSearcher.searchUnit(ppTarget);
    return printingFee + ppUnit.value;
  }
}
