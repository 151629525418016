import { PaperInfoSearcher } from '../../../table/master/unitpricesearcher/paperinfo';
import { BodyBase } from './base';

export function calc_spare_papers(
  numOfColors: number,
  numOfPrinters: number
): number {
  let coefficient: number;
  if (numOfColors === 1) {
    coefficient = 250;
  } else {
    coefficient = 100 * numOfColors;
  }

  return coefficient * numOfPrinters;
}

export class PaperCalcurator extends BodyBase {
  private feeSearcher: PaperInfoSearcher;

  public constructor(paperSearcher: PaperInfoSearcher) {
    super();
    this.feeSearcher = paperSearcher;
  }

  public execute(
    paperId: number,
    numOfColors: number,
    pages: number,
    copies: number,
    includePages: number,
    cuttingConvertion: number
  ): number {
    const plates = this.getPrintingPlates(pages, includePages);
    const numOfPrinters = Math.ceil(plates / 2);
    const numOfPaper = copies * numOfPrinters;
    const numOfSparePaper = calc_spare_papers(numOfColors, numOfPrinters);
    let totalSheets = numOfPaper + numOfSparePaper;
    //  全紙へ換算
    totalSheets = totalSheets / cuttingConvertion;

    const unit = this.feeSearcher.searchUnit(paperId);
    const paperUnits = Math.ceil(totalSheets / unit.sheets);
    return paperUnits * unit.value;
  }
}
