import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';

export class GradeUnitConverter
  implements DataFileConverter<Table.GradeTypeUnitPrice[]> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.Kikaku,
    FileData.DataType.Henshu,
    FileData.DataType.Kousei,
    FileData.DataType.CoverDesign,
    FileData.DataType.FrontCoverDesign,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.GradeTypeUnitPrice[] {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (
      typeof parsed_data.values === 'undefined' ||
      typeof parsed_data.values[0].grade === 'undefined'
    ) {
      throw new Error('invalid type');
    }

    let data = parsed_data as FileData.GradeFee;
    let result: Table.GradeTypeUnitPrice[] = [];

    for (let unit of data.values) {
      let row: Table.GradeTypeUnitPrice = {
        genreId: data.genreId,
        grade: this.gradeFileToTable(unit.grade),
        value: unit.value,
      };
      result.push(row);
    }

    return result;
  }

  public gradeFileToTable(type: string): Table.GradeType {
    let result: Table.GradeType;
    switch (type) {
      case FileData.GradeType.Simple:
        result = Table.GradeType.Simple;
        break;
      case FileData.GradeType.Standard:
        result = Table.GradeType.Standard;
        break;
      default:
        throw new Error('invalid type');
    }

    return result;
  }
}
