import { IFormsetConverter } from './iformset';
import { ScaningFormset } from '../../press/subtotal/scaning';
import * as InputForm from '../structs';

export class PressScaningFormsetConverter
  implements IFormsetConverter<ScaningFormset> {
  public convert(input: InputForm.InputParams): ScaningFormset {
    let result: ScaningFormset = {
      genreId: input.genreId,
      scanCount: input.scanCount,
    };

    return result;
  }
}
