import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';

export class GenshiIncludePagesConverter
  implements DataFileConverter<Table.GenshiIncludePages> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.BodyIncludePages,
    FileData.DataType.CoverIncludePages,
    FileData.DataType.CoverBeltIncludePages,
    FileData.DataType.FrontCoverIncludePages,
    FileData.DataType.MikaeshiIncludePages,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.GenshiIncludePages {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (typeof parsed_data.genshiIncludes === 'undefined') {
      throw new Error('invalid type');
    }

    let data = parsed_data as FileData.IdGenshiIncludeTable;
    let result: Table.GenshiIncludePages = {
      hangataId: data.hangataId,
      genshiIncludes: data.genshiIncludes,
    };

    return result;
  }
}
