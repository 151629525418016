import * as Searcher from '../../table/b/unitpricesearcher';

export class ProofReadingTableCalculator {
  private feeSearcher: Searcher.HangataTypeSearcher;

  public constructor(feeSearcher: Searcher.HangataTypeSearcher) {
    this.feeSearcher = feeSearcher;
  }

  public execute(hangataId: string): number {
    const unit = this.feeSearcher.searchUnit(hangataId);

    return unit.value;
  }
}
