import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import * as Searcher from '../../../table/b/unitpricesearcher';
import { DataType } from '../../../table/b/datafile/structs';

export class HangataType implements IRegister {
  readonly unitpriceKeys = [
    {
      key: DataType.HakuoshiPrintingPlate,
      registerKey: TYPES.Searcher.hakuoshi.printingPlate,
    },
    {
      key: DataType.MikaeshiEhonPrintingPlate,
      registerKey: TYPES.Searcher.mikaeshi.printingPlate,
    },
    {
      key: DataType.MikaeshiProofReading,
      registerKey: TYPES.Searcher.mikaeshi.proofreading,
    },
    {
      key: DataType.BodyPrintingPlate,
      registerKey: TYPES.Searcher.body.printingPlate,
    },
    {
      key: DataType.BodyProofReadingMono,
      registerKey: TYPES.Searcher.body.proofreading.mono,
    },
    {
      key: DataType.BodyProofReadingColor,
      registerKey: TYPES.Searcher.body.proofreading.color,
    },
    {
      key: DataType.CoverPrintingPlate,
      registerKey: TYPES.Searcher.cover.printingPlate.varnish,
    },
    {
      key: DataType.CoverPrintingPlatePP,
      registerKey: TYPES.Searcher.cover.printingPlate.pp,
    },
    {
      key: DataType.CoverProofReading,
      registerKey: TYPES.Searcher.cover.proofreading,
    },
    {
      key: DataType.CoverBeltPrintingPlateTokushoku,
      registerKey: TYPES.Searcher.coverbelt.printingPlate.tokushoku,
    },
    {
      key: DataType.CoverBeltPrintingPlateColor,
      registerKey: TYPES.Searcher.coverbelt.printingPlate.color,
    },
    {
      key: DataType.CoverBeltProofReading,
      registerKey: TYPES.Searcher.coverbelt.proofReading,
    },
    {
      key: DataType.FrontCoverPrintingPlateTokushoku,
      registerKey: TYPES.Searcher.frontcover.printingPlate.tokushoku,
    },
    {
      key: DataType.FrontCoverPrintingPlateColor,
      registerKey: TYPES.Searcher.frontcover.printingPlate.color,
    },
    {
      key: DataType.FrontCoverProofReading,
      registerKey: TYPES.Searcher.frontcover.proofReading,
    },
  ];

  public register(container: Container) {
    for (const unitpriceKey of this.unitpriceKeys) {
      container.singleton(unitpriceKey.registerKey, (c: Container) => {
        const unitprices = c.make(TYPES.UnitPrice.table.b) as Map<
          string,
          any[]
        >;
        const dataKey = unitpriceKey.key;
        const unitprice = unitprices.get(dataKey);
        if (typeof unitprice === 'undefined') {
          throw new Error('Not Exists Data: ' + dataKey);
        }

        const result = new Searcher.HangataTypeSearcher(
          unitprice,
          Searcher.HangataTypeComparator
        );
        return result;
      });
    }
  }
}
