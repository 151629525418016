import { IRegister } from './iregister';
import { Container } from '../container';
import { Genre } from './searchers/genre';
import { GenreRange } from './searchers/genrerange';
import { Grade } from './searchers/grade';
import { HangataCopyPage } from './searchers/hangatacopypage';
import { HangataGenshiInclude } from './searchers/hangatagenshiinclude';
import { HangataRange } from './searchers/hangatarange';
import { HangataType } from './searchers/hangatatype';
import { Promotion } from './searchers/promotion';
import { PaperInfo } from './searchers/paperinfo';

export class Searcher implements IRegister {
  readonly providers: IRegister[];

  public constructor() {
    const providers: IRegister[] = [
      new Genre(),
      new GenreRange(),
      new Grade(),
      new HangataCopyPage(),
      new HangataGenshiInclude(),
      new HangataRange(),
      new HangataType(),
      new Promotion(),
      new PaperInfo(),
    ];

    this.providers = providers;
  }

  public register(container: Container) {
    for (const provider of this.providers) {
      provider.register(container);
    }
  }
}
