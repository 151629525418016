export class CoverBase {
  public getPrintingPlates(hasCover: boolean, isPP: boolean): number {
    let plates: number;
    if (hasCover) {
      // 特色 1
      plates = 1;
    } else {
      // カラー
      plates = 4;
    }

    if (isPP) {
      return plates;
    } else {
      // ニス
      return plates + 1;
    }
  }
}
