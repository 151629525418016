export class BodyBase {
  public getPrintingPlates(bodyPages: number, includePages: number): number {
    if (bodyPages === 9) {
      return 0;
    }

    let plates = Math.ceil(bodyPages / includePages);
    let surplus = plates % 2;
    if (surplus > 0) {
      return plates + 1;
    } else {
      return plates;
    }
  }
}
