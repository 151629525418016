import { CoverBeltBase } from './coverbeltbase';
import * as Searcher from '../../../table/b/unitpricesearcher';
import { CoverBeltType } from '../../../table/b/structs';

export class CoverBeltPrintingPlateCalcurator extends CoverBeltBase {
  private tokushokuFee: Searcher.HangataTypeSearcher;
  private colorFee: Searcher.HangataTypeSearcher;

  public constructor(
    tokushokuFee: Searcher.HangataTypeSearcher,
    colorFee: Searcher.HangataTypeSearcher
  ) {
    super();
    this.tokushokuFee = tokushokuFee;
    this.colorFee = colorFee;
  }

  public execute(hangataId: string, type: CoverBeltType, isPP: boolean) {
    let searcher: Searcher.HangataTypeSearcher;
    if (type === CoverBeltType.Colors) {
      searcher = this.colorFee;
    } else {
      searcher = this.tokushokuFee;
    }

    const unit = searcher.searchUnit(hangataId);
    const printingPlates = this.getPrintingPlates(type, isPP);
    return printingPlates * unit.value;
  }
}
