import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import * as Searcher from '../../../table/a/unitpricesearcher';
import { DataType } from '../../../table/a/datafile/structs';

export class Grade implements IRegister {
  readonly unitpriceKeys = [
    { key: DataType.Kikaku, registerKey: TYPES.Searcher.planning },
    { key: DataType.Henshu, registerKey: TYPES.Searcher.editing },
    { key: DataType.Kousei, registerKey: TYPES.Searcher.proofreading },
    { key: DataType.CoverDesign, registerKey: TYPES.Searcher.coverdesign },
    {
      key: DataType.FrontCoverDesign,
      registerKey: TYPES.Searcher.frontcoverdesign,
    },
  ];

  public register(container: Container) {
    for (const unitpriceKey of this.unitpriceKeys) {
      container.singleton(unitpriceKey.registerKey, (c: Container) => {
        const unitprices = c.make(TYPES.UnitPrice.table.a) as Map<
          string,
          any[]
        >;
        const dataKey = unitpriceKey.key;
        const unitprice = unitprices.get(dataKey);
        if (typeof unitprice === 'undefined') {
          throw new Error('Not Exists Data: ' + dataKey);
        }

        const result = new Searcher.GradeTypeSearcher(
          unitprice,
          Searcher.GradeComparator
        );
        return result;
      });
    }
  }
}
