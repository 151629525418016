import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import { HangataGenshiIncludeSearcher } from '../../../table/b/hangatagenshiincludesearcher';
import { DataType } from '../../../table/b/datafile/structs';

export class HangataGenshiInclude implements IRegister {
  readonly unitpriceKeys = [
    {
      key: DataType.BodyIncludePages,
      registerKey: TYPES.Searcher.body.includePages,
    },
    {
      key: DataType.CoverIncludePages,
      registerKey: TYPES.Searcher.cover.includePages,
    },
    {
      key: DataType.CoverBeltIncludePages,
      registerKey: TYPES.Searcher.coverbelt.includePages,
    },
    {
      key: DataType.FrontCoverIncludePages,
      registerKey: TYPES.Searcher.frontcover.includePages,
    },
    {
      key: DataType.MikaeshiIncludePages,
      registerKey: TYPES.Searcher.mikaeshi.includePages,
    },
  ];

  public register(container: Container) {
    for (const unitpriceKey of this.unitpriceKeys) {
      container.singleton(unitpriceKey.registerKey, (c: Container) => {
        const paperInfo = c.make(TYPES.Searcher.master.paperinfo);
        const unitprices = c.make(TYPES.UnitPrice.table.b) as Map<
          string,
          any[]
        >;
        const dataKey = unitpriceKey.key;
        const unitprice = unitprices.get(dataKey);
        if (typeof unitprice === 'undefined') {
          throw new Error('Not Exists Data: ' + dataKey);
        }

        const result = new HangataGenshiIncludeSearcher(paperInfo, unitprice);
        return result;
      });
    }
  }
}
