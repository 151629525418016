import * as Table from '../table/a/structs';
import * as Searcher from '../table/a/unitpricesearcher';

export class GradeTypePageCalcurator {
  private searcher: Searcher.GradeTypeSearcher;

  public constructor(processer: Searcher.GradeTypeSearcher) {
    this.searcher = processer;
  }

  public execute(
    genreId: string,
    pages: number,
    grade: Table.GradeType
  ): number {
    let target: Searcher.GradeCompareSet = {
      genreId: genreId,
      grade: grade,
    };
    let unitprice = this.searcher.searchUnit(target);
    return pages * unitprice.value;
  }
}

export class GradeTypeCalcurator {
  private searcher: Searcher.GradeTypeSearcher;

  public constructor(processer: Searcher.GradeTypeSearcher) {
    this.searcher = processer;
  }

  public execute(genreId: string, grade: Table.GradeType): number {
    let target: Searcher.GradeCompareSet = {
      genreId: genreId,
      grade: grade,
    };
    let unitprice = this.searcher.searchUnit(target);
    return unitprice.value;
  }
}

export class GenreUnitPriceCalculator {
  private searcher: Searcher.GenreSearcher;

  public constructor(processer: Searcher.GenreSearcher) {
    this.searcher = processer;
  }

  public execute(genreId: string): number {
    let unitprice = this.searcher.searchUnit(genreId);
    return unitprice.value;
  }
}

export class GenreUnitPricePageCalculator {
  private searcher: Searcher.GenreSearcher;

  public constructor(processer: Searcher.GenreSearcher) {
    this.searcher = processer;
  }

  public execute(genreId: string, pages: number): number {
    let unitprice = this.searcher.searchUnit(genreId);
    return pages * unitprice.value;
  }
}
