import { CoverBase } from './coverbase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class FrontCoverPrintingCalcurator extends CoverBase {
  private tokushokuFeeSearcher: Searcher.HangataRangeSearcher;
  private colorFeeSearcher: Searcher.HangataRangeSearcher;
  private ppFeeSearcher: Searcher.HangataRangeSearcher;

  public constructor(
    tokushokuFeeSearcher: Searcher.HangataRangeSearcher,
    colorFeeSearcher: Searcher.HangataRangeSearcher,
    ppFeeSearcher: Searcher.HangataRangeSearcher
  ) {
    super();
    this.tokushokuFeeSearcher = tokushokuFeeSearcher;
    this.colorFeeSearcher = colorFeeSearcher;
    this.ppFeeSearcher = ppFeeSearcher;
  }

  public execute(
    hangataId: string,
    hasCover: boolean,
    isPP: boolean,
    copies: number
  ) {
    let printingPlates = this.getPrintingPlates(hasCover, isPP);

    let target: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };

    let printingFee: number;
    if (hasCover) {
      let unit = this.tokushokuFeeSearcher.searchUnit(target);
      if (isPP) {
        printingFee = printingPlates * unit.value * 1.3;
      } else {
        let tokushokuPlates = printingPlates - 1;
        printingFee = tokushokuPlates * unit.value * 1.3 + unit.value;
      }

      printingFee = parseInt(printingFee.toFixed(1));
    } else {
      let unit = this.colorFeeSearcher.searchUnit(target);
      printingFee = printingPlates * unit.value;
    }

    if (isPP) {
      let unit = this.ppFeeSearcher.searchUnit(target);
      printingFee = printingFee + unit.value;
    }

    return printingFee;
  }
}
