import { CoverBase } from './coverbase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class CoverPrintingPlateCalcurator extends CoverBase {
  private varnishFee: Searcher.HangataTypeSearcher;
  private ppFee: Searcher.HangataTypeSearcher;

  public constructor(
    varnishFee: Searcher.HangataTypeSearcher,
    ppFee: Searcher.HangataTypeSearcher
  ) {
    super();
    this.varnishFee = varnishFee;
    this.ppFee = ppFee;
  }

  public execute(hangataId: string, isPP: boolean) {
    let searcher: Searcher.HangataTypeSearcher;
    if (isPP) {
      searcher = this.ppFee;
    } else {
      searcher = this.varnishFee;
    }

    const unit = searcher.searchUnit(hangataId);
    const printingPlates = this.getPrintingPlates(isPP);
    return printingPlates * unit.value;
  }
}
