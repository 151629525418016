import { BasicUnitConverter } from '../../table/a/datafile/converter/basicunit';
import { GenreUnitConverter } from '../../table/a/datafile/converter/genreunit';
import { GradeUnitConverter } from '../../table/a/datafile/converter/gradeunit';

export class TableAConverter {
  private basic: BasicUnitConverter;
  private genre: GenreUnitConverter;
  private grade: GradeUnitConverter;

  public constructor() {
    this.basic = new BasicUnitConverter();
    this.genre = new GenreUnitConverter();
    this.grade = new GradeUnitConverter();
  }

  public convert(data: any[]): Map<string, any[]> {
    const result = new Map<string, any[]>();
    for (const row of data) {
      let stack = result.get(row.type);
      if (typeof stack === 'undefined') {
        stack = [];
      }

      if (this.basic.hasType(row.type)) {
        const unitprice = this.basic.convert(row);
        result.set(row.type, stack.concat(unitprice));
      } else if (this.grade.hasType(row.type)) {
        const unitprice = this.grade.convert(row);
        result.set(row.type, stack.concat(unitprice));
      } else if (this.genre.hasType(row.type)) {
        const unitprice = this.genre.convert(row);
        stack.push(unitprice);
        result.set(row.type, stack);
      }
    }

    return result;
  }
}
