import * as BSearcher from '../../table/b/unitpricesearcher';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { CoverPrintingPlateCalcurator } from '../../press/calcurators/cover/printingplate';
import { CoverPrintingCalcurator } from '../../press/calcurators/cover/printing';
import { ProofReadingTableCalculator } from '../../press/calcurators/proofreadingtable';
import { PaperCalcurator } from '../../press/calcurators/paper';

export class CoverSubTotal implements ISubTotal {
  private searchers: CoverTables;
  private formset: CoverFormSet;

  public constructor(formset: CoverFormSet, searchers: CoverTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];
    if (this.formset.hasCover === false) {
      return subtotal;
    }

    const plateCalc = new CoverPrintingPlateCalcurator(
      this.searchers.coverPrintingPlateVarnish,
      this.searchers.coverPrintingPlatePP
    );
    let result = plateCalc.execute(
      this.formset.hangataId,
      this.formset.isCoverPP
    );
    subtotal.push({ id: 'coverPrintingPlate', value: result });

    const printingCalc = new CoverPrintingCalcurator(
      this.searchers.coverPrinting,
      this.searchers.coverPP
    );
    result = printingCalc.execute(
      this.formset.hangataId,
      this.formset.isCoverPP,
      this.formset.copies
    );
    subtotal.push({ id: 'coverPrinting', value: result });

    const proofReadingCalc = new ProofReadingTableCalculator(
      this.searchers.coverProofreading
    );
    result = proofReadingCalc.execute(this.formset.hangataId);
    subtotal.push({ id: 'coverProofreading', value: result });

    const paperCalc = new PaperCalcurator(this.searchers.coverPaper);
    result = paperCalc.execute(
      this.formset.coverPaperId,
      this.formset.coverNumOfColors,
      this.formset.copies,
      this.formset.coverIncludePages,
      this.formset.coverCuttingConvertion
    );
    subtotal.push({ id: 'coverPaper', value: result });

    return subtotal;
  }
}

export interface CoverTables {
  [key: string]:
    | BSearcher.HangataTypeSearcher
    | BSearcher.HangataRangeSearcher
    | PaperInfoSearcher;
  coverPrintingPlateVarnish: BSearcher.HangataTypeSearcher;
  coverPrintingPlatePP: BSearcher.HangataTypeSearcher;
  coverPrinting: BSearcher.HangataRangeSearcher;
  coverPP: BSearcher.HangataRangeSearcher;
  coverProofreading: BSearcher.HangataTypeSearcher;
  coverPaper: PaperInfoSearcher;
}

export interface CoverFormSet {
  hangataId: string;
  isCoverPP: boolean;
  copies: number;
  hasCover: boolean;
  coverPaperId: number;
  coverNumOfColors: number; // 4 All Color
  coverIncludePages: number; //GenshiIncludePages
  coverCuttingConvertion: number; //GenshiTYpe.cuttingConvertion
}
