import { container } from './container/index';
import { TYPES } from './container/types';
import * as formEnums from './formset/structs';
import * as Table from './main/settabledata';
import * as Formset from './main/setformset';
import { calc_subtotal } from './main/calcsubtotals';
import { SubTotalResult } from './subtotal/structs';

export const inputEnums = formEnums;

export function setTableA(parsed_data: any[]) {
  Table.set_table_a(container, parsed_data);
}

export function setTableB(parsed_data: any[]) {
  Table.set_table_b(container, parsed_data);
}

export function setTableE(parsed_data: any[]) {
  Table.set_table_e(container, parsed_data);
}

export function setFormset(data: any) {
  Formset.set_formset(container, data);
}

export function setMasterPaper(parsed_data: any) {
  Table.set_master_paper(container, parsed_data);
}

export function calcSubTotal(): SubTotalResult[] {
  const checkKeys = [
    TYPES.UnitPrice.table.a,
    TYPES.UnitPrice.table.b,
    TYPES.UnitPrice.table.e,
    TYPES.UnitPrice.table.master.paper,
  ];
  for (const key of checkKeys) {
    if (container.has(key) === false) {
      throw new Error('Not Settings UnitPrice: ' + key.toString());
    }
  }

  const result = calc_subtotal(container);
  return result;
}
