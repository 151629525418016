import { MikaeshiBase } from './mikaeshibase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class MikaeshiPrintingPlateCalcurator extends MikaeshiBase {
  private feeSearcher: Searcher.HangataTypeSearcher;

  public constructor(feeSearcher: Searcher.HangataTypeSearcher) {
    super();
    this.feeSearcher = feeSearcher;
  }

  public execute(hangataId: string, isPictureBook: boolean) {
    if (isPictureBook === false) {
      return 0;
    }
    let unit = this.feeSearcher.searchUnit(hangataId);
    let printingPlates = this.getPrintingPlates(isPictureBook);
    return printingPlates * unit.value;
  }
}
