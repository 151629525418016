import { IFormsetConverter } from './iformset';
import { CoverBeltFormSet } from '../../press/subtotal/coverbelt';
import * as InputForm from '../structs';
import { HangataGenshiIncludeSearcher } from '../../table/b/hangatagenshiincludesearcher';
import * as Table from '../../table/b/structs';
import { GenshiTypeParser } from '../../table/parser/genshitype';
import { GenshiInclude, GenshiType } from '../../table/b/structs';

export class PressCoverBeltFormsetConverter
  implements IFormsetConverter<CoverBeltFormSet> {
  private genshiIncludeSearcher: HangataGenshiIncludeSearcher;

  public constructor(genshiIncludeSearcher: HangataGenshiIncludeSearcher) {
    this.genshiIncludeSearcher = genshiIncludeSearcher;
  }

  public convert(input: InputForm.InputParams): CoverBeltFormSet {
    const hangataId = input.hangataId;
    let coverBeltPaperId = 0;
    let coverBeltIncludePages: GenshiInclude = {
      genshiId: '',
      includePages: 0,
    };
    let genshiType: GenshiType = {
      youshiSize: '',
      cuttingConvertion: 0,
    };

    if (input.hasCoverBelt) {
      coverBeltPaperId = input.coverbeltPaperId;
      coverBeltIncludePages = this.genshiIncludeSearcher.searchPageInclude(
        coverBeltPaperId,
        hangataId
      );
      const parser = new GenshiTypeParser();
      genshiType = parser.parse(coverBeltIncludePages.genshiId);
    }

    let result: CoverBeltFormSet = {
      hangataId: hangataId,
      copies: input.copies,
      hasCoverBelt: input.hasCoverBelt,
      coverBeltType: Table.CoverBeltType.Colors,
      isCoverBeltPP: input.isPP,
      coverBeltPaperId: coverBeltPaperId,
      coverBeltNumOfColors: 4,
      coverBeltIncludePages: coverBeltIncludePages.includePages,
      coverBeltCuttingConvertion: genshiType.cuttingConvertion,
    };

    return result;
  }
}
