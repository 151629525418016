import { GenshiType } from '../b/structs';

export class GenshiTypeParser {
  public parse(genshiId: string): GenshiType {
    let splittedItems: string[] = genshiId.split('-');
    if (splittedItems.length === 1) {
      throw new Error('invalid genshiId');
    }

    let youshiSize = splittedItems[0];
    let cutting = splittedItems[1];
    let cuttingConvertion: number;
    switch (cutting) {
      case 'all':
        cuttingConvertion = 1;
        break;
      case 'half':
        cuttingConvertion = 2;
        break;
      case 'quarter':
        cuttingConvertion = 4;
        break;
      default:
        throw new Error('unknown cutting: ' + cutting);
    }

    let result: GenshiType = {
      youshiSize: youshiSize,
      cuttingConvertion: cuttingConvertion,
    };
    return result;
  }
}
