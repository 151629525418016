import { Range } from '../common';

export interface Hangata {
  id: string;
  name: string;
}

export interface Genshi {
  id: string;
  name: string;
}

export enum BindingType {
  hard,
  soft,
}

export enum CoverBeltType {
  Tokushoku2,
  Colors,
}

export interface HangataTypeUnitPrice {
  hangataId: string;
  value: number;
}

export interface GenshiType {
  youshiSize: string;
  cuttingConvertion: number;
}

export interface GenshiInclude {
  genshiId: string;
  includePages: number; //取得頁数・面付
}

export interface GenshiIncludePages {
  hangataId: string;
  genshiIncludes: GenshiInclude[];
}

export interface HangataCopyRangeUnitPrice extends HangataTypeUnitPrice {
  copies: Range;
}

export interface HangataCopyPageUnitPrice extends HangataCopyRangeUnitPrice {
  pages: Range;
}
