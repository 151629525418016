import { Container } from '../container/container';
import { TYPES } from '../container/types';
import { TableAConverter } from './converters/tablea';
import { TableBConverter } from './converters/tableb';

export function set_table_a(
  container: Container,
  parsed_data: any[],
  conv?: TableAConverter
) {
  if (typeof conv === 'undefined') {
    conv = new TableAConverter();
  }
  const data = conv.convert(parsed_data);
  container.instance(TYPES.UnitPrice.table.a, () => {
    return data;
  });
}

export function set_table_b(
  container: Container,
  parsed_data: any[],
  conv?: TableBConverter
) {
  if (typeof conv === 'undefined') {
    conv = new TableBConverter();
  }
  const data = conv.convert(parsed_data);
  container.instance(TYPES.UnitPrice.table.b, () => {
    return data;
  });
}

export function set_table_e(container: Container, parsed_data: any[]) {
  container.instance(TYPES.UnitPrice.table.e, () => {
    return parsed_data;
  });
}

export function set_master_paper(container: Container, parsed_data: any[]) {
  container.instance(TYPES.UnitPrice.table.master.paper, () => {
    return parsed_data;
  });
}
