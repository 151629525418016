import { Container } from '../container/container';
import { SubTotalResult } from '../subtotal/structs';
import { ISubTotal } from '../subtotal/isubtotal';
import { TYPES } from '../container/types';

export function calc_subtotal(container: Container): SubTotalResult[] {
  const typeKeys = [
    TYPES.SubTotal.basic,
    TYPES.SubTotal.distribution,
    TYPES.SubTotal.prepress,
    TYPES.SubTotal.press,
  ];

  let result: SubTotalResult[] = [];
  for (const key of typeKeys) {
    const calc = container.make(key) as ISubTotal;
    const subtotal = calc.getSubTotal();
    result = result.concat(subtotal);
  }

  return result;
}
