import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import * as Searcher from '../../../table/e/unitpricesearcher';
import * as Table from '../../../table/e/structs';

export class Promotion implements IRegister {
  public register(container: Container) {
    container.singleton(
      TYPES.Searcher.distribution.promotion,
      (c: Container) => {
        const unitprices = c.make(
          TYPES.UnitPrice.table.e
        ) as Table.PromotionFee[];
        return new Searcher.PromotionSearcher(
          unitprices,
          Searcher.PromotionComparator
        );
      }
    );
  }
}
