import * as Searcher from '../../../table/b/unitpricesearcher';
import { BodyBase } from './base';

export class BodyProofReadingCalculator extends BodyBase {
  private monoFee: Searcher.HangataTypeSearcher;
  private colorFee: Searcher.HangataTypeSearcher;

  public constructor(
    monoFee: Searcher.HangataTypeSearcher,
    colorFee: Searcher.HangataTypeSearcher
  ) {
    super();
    this.monoFee = monoFee;
    this.colorFee = colorFee;
  }

  public execute(
    hangataId: string,
    isColor: boolean,
    bodyPages: number,
    includePages: number
  ): number {
    let searcher: Searcher.HangataTypeSearcher;
    if (isColor) {
      searcher = this.colorFee;
    } else {
      searcher = this.monoFee;
    }

    const unit = searcher.searchUnit(hangataId);

    const printingPlates = this.getPrintingPlates(bodyPages, includePages);
    let result = unit.value * printingPlates;
    return result;
  }
}
