import * as BSearcher from '../../table/b/unitpricesearcher';
import { CoverBeltType } from '../../table/b/structs';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { CoverBeltPrintingPlateCalcurator } from '../../press/calcurators/coverbelt/printingplate';
import { CoverBeltPrintingCalcurator } from '../../press/calcurators/coverbelt/printing';
import { ProofReadingTableCalculator } from '../../press/calcurators/proofreadingtable';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';
import { PaperCalcurator } from '../../press/calcurators/paper';

export class CoverBeltSubTotal implements ISubTotal {
  private searchers: CoverBeltTables;
  private formset: CoverBeltFormSet;

  public constructor(formset: CoverBeltFormSet, searchers: CoverBeltTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    if (this.formset.hasCoverBelt === false) {
      return subtotal;
    }

    const plateCalc = new CoverBeltPrintingPlateCalcurator(
      this.searchers.printingPlateTokushoku,
      this.searchers.printingPlateColor
    );
    let result = plateCalc.execute(
      this.formset.hangataId,
      this.formset.coverBeltType,
      this.formset.isCoverBeltPP
    );
    subtotal.push({ id: 'coverBeltPrintingPlate', value: result });

    const printingCalc = new CoverBeltPrintingCalcurator(
      this.searchers.printingTokushoku,
      this.searchers.printingColor,
      this.searchers.printingPP
    );

    result = printingCalc.execute(
      this.formset.hangataId,
      this.formset.coverBeltType,
      this.formset.isCoverBeltPP,
      this.formset.copies
    );
    subtotal.push({ id: 'coverBeltPrinting', value: result });

    const proofReadingCalc = new ProofReadingTableCalculator(
      this.searchers.proofReading
    );

    result = proofReadingCalc.execute(this.formset.hangataId);
    subtotal.push({ id: 'coverBeltProofreading', value: result });

    const paperCalc = new PaperCalcurator(this.searchers.paper);
    result = paperCalc.execute(
      this.formset.coverBeltPaperId,
      this.formset.coverBeltNumOfColors,
      this.formset.copies,
      this.formset.coverBeltIncludePages,
      this.formset.coverBeltCuttingConvertion
    );
    subtotal.push({ id: 'coverBeltPaper', value: result });

    return subtotal;
  }
}

export interface CoverBeltTables {
  [key: string]:
    | BSearcher.HangataTypeSearcher
    | BSearcher.HangataRangeSearcher
    | PaperInfoSearcher;
  printingPlateTokushoku: BSearcher.HangataTypeSearcher;
  printingPlateColor: BSearcher.HangataTypeSearcher;
  printingTokushoku: BSearcher.HangataRangeSearcher;
  printingColor: BSearcher.HangataRangeSearcher;
  printingPP: BSearcher.HangataRangeSearcher;
  proofReading: BSearcher.HangataTypeSearcher;
  paper: PaperInfoSearcher;
}

export interface CoverBeltFormSet {
  hangataId: string;
  copies: number;
  hasCoverBelt: boolean;
  coverBeltType: CoverBeltType; //coverありの場合カラー
  isCoverBeltPP: boolean; // cover/frontconver と同じ
  coverBeltPaperId: number; // cover/frontconver と同じ
  coverBeltNumOfColors: number; //CoverBeltType 2 or 4
  coverBeltIncludePages: number; //GenshiIncludePages
  coverBeltCuttingConvertion: number; //GenshiTYpe.cuttingConvertion
}
