export const UnitPrice = {
  table: {
    a: Symbol('unitprice.table.a'),
    b: Symbol('unitprice.table.b'),
    e: Symbol('unitprice.table.e'),
    master: {
      paper: Symbol('unitprice.table.master.paper'),
    },
  },
};
