import * as BSearcher from '../../table/b/unitpricesearcher';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { MikaeshiPrintingCalcurator } from '../../press/calcurators/mikaeshi/printing';
import { MikaeshiPrintingPlateCalcurator } from '../../press/calcurators/mikaeshi/printingplate';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';
import { PaperCalcurator } from '../../press/calcurators/paper';
import { ProofReadingTableCalculator } from '../../press/calcurators/proofreadingtable';

export class MikaeshiSubTotal implements ISubTotal {
  private searchers: MikaeshiTables;
  private formset: MikaeshiFormSet;

  public constructor(formset: MikaeshiFormSet, searchers: MikaeshiTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    if (this.formset.hasMikaeshi === false) {
      return subtotal;
    }

    const printingCalc = new MikaeshiPrintingCalcurator(
      this.searchers.printing
    );

    let result = printingCalc.execute(
      this.formset.hangataId,
      this.formset.isPictureBook,
      this.formset.copies
    );
    subtotal.push({ id: 'mikaeshiPrinting', value: result });

    const printingPlateCalc = new MikaeshiPrintingPlateCalcurator(
      this.searchers.printingPlate
    );
    result = printingPlateCalc.execute(
      this.formset.hangataId,
      this.formset.isPictureBook
    );
    subtotal.push({ id: 'mikaeshiPrintingPlate', value: result });

    let proofReading = 0;
    if (this.formset.isPictureBook) {
      const proofReadingCalc = new ProofReadingTableCalculator(
        this.searchers.proofreading
      );
      proofReading = proofReadingCalc.execute(this.formset.hangataId);
    }
    subtotal.push({ id: 'mikaeshiProofreading', value: proofReading });

    const paperCalc = new PaperCalcurator(this.searchers.paper);
    result = paperCalc.execute(
      this.formset.mikaeshiPaperId,
      this.formset.mikaeshiNumOfColors,
      this.formset.copies,
      this.formset.mikaeshiIncludePages,
      this.formset.mikaeshiCuttingConvertion
    );
    subtotal.push({ id: 'mikaeshiPaper', value: result });

    return subtotal;
  }
}

export interface MikaeshiTables {
  [key: string]:
    | BSearcher.HangataTypeSearcher
    | BSearcher.HangataRangeSearcher
    | PaperInfoSearcher;
  printingPlate: BSearcher.HangataTypeSearcher;
  printing: BSearcher.HangataRangeSearcher;
  proofreading: BSearcher.HangataTypeSearcher;
  paper: PaperInfoSearcher;
}

export interface MikaeshiFormSet {
  hangataId: string;
  copies: number;
  hasMikaeshi: boolean;
  isPictureBook: boolean; // form genre
  mikaeshiPaperId: number;
  mikaeshiNumOfColors: number; // 1
  mikaeshiIncludePages: number; //GenshiIncludePages
  mikaeshiCuttingConvertion: number; //GenshiTYpe.cuttingConvertion
}
