import * as BSearcher from '../../table/b/unitpricesearcher';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { FrontCoverPrintingPlateCalcurator } from '../../press/calcurators/frontcover/printingplate';
import { FrontCoverPrintingCalcurator } from '../../press/calcurators/frontcover/printing';
import { ProofReadingTableCalculator } from '../../press/calcurators/proofreadingtable';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';
import { PaperCalcurator } from '../../press/calcurators/paper';

export class FrontCoverSubTotal implements ISubTotal {
  private searchers: FrontCoverTables;
  private formset: FrontCoverFormSet;

  public constructor(formset: FrontCoverFormSet, searchers: FrontCoverTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    const plateCalc = new FrontCoverPrintingPlateCalcurator(
      this.searchers.printingPlateTokushoku,
      this.searchers.printingPlateColor
    );
    let result = plateCalc.execute(
      this.formset.hangataId,
      this.formset.hasCover,
      this.formset.isFrontCoverPP
    );
    subtotal.push({ id: 'frontCoverPrintingPlate', value: result });

    const printingCalc = new FrontCoverPrintingCalcurator(
      this.searchers.printingTokushoku,
      this.searchers.printingColor,
      this.searchers.printingPP
    );

    result = printingCalc.execute(
      this.formset.hangataId,
      this.formset.hasCover,
      this.formset.isFrontCoverPP,
      this.formset.copies
    );
    subtotal.push({ id: 'frontCoverPrinting', value: result });

    const proofReadingCalc = new ProofReadingTableCalculator(
      this.searchers.proofReading
    );

    result = proofReadingCalc.execute(this.formset.hangataId);
    subtotal.push({ id: 'frontCoverProofreading', value: result });

    const paperCalc = new PaperCalcurator(this.searchers.paper);
    result = paperCalc.execute(
      this.formset.frontCoverPaperId,
      this.formset.frontCoverNumOfColors,
      this.formset.copies,
      this.formset.frontCoverIncludePages,
      this.formset.frontCoverCuttingConvertion
    );
    subtotal.push({ id: 'frontCoverPaper', value: result });

    return subtotal;
  }
}

export interface FrontCoverTables {
  [key: string]:
    | BSearcher.HangataTypeSearcher
    | BSearcher.HangataRangeSearcher
    | PaperInfoSearcher;
  printingPlateTokushoku: BSearcher.HangataTypeSearcher;
  printingPlateColor: BSearcher.HangataTypeSearcher;
  printingTokushoku: BSearcher.HangataRangeSearcher;
  printingColor: BSearcher.HangataRangeSearcher;
  printingPP: BSearcher.HangataRangeSearcher;
  proofReading: BSearcher.HangataTypeSearcher;
  paper: PaperInfoSearcher;
}

export interface FrontCoverFormSet {
  hangataId: string;
  genreId: string;
  copies: number;
  hasCover: boolean;
  isFrontCoverPP: boolean; // hasCover && form PP有無
  frontCoverPaperId: number;
  frontCoverNumOfColors: number;
  frontCoverIncludePages: number; //GenshiIncludePages
  frontCoverCuttingConvertion: number; //GenshiTYpe.cuttingConvertion
}
