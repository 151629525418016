import { IFormsetConverter } from './iformset';
import { CoverFormSet } from '../../press/subtotal/cover';
import * as InputForm from '../structs';
import { HangataGenshiIncludeSearcher } from '../../table/b/hangatagenshiincludesearcher';
import { GenshiTypeParser } from '../../table/parser/genshitype';
import { GenshiInclude, GenshiType } from '../../table/b/structs';

export class PressCoverFormsetConverter
  implements IFormsetConverter<CoverFormSet> {
  private genshiIncludeSearcher: HangataGenshiIncludeSearcher;

  public constructor(genshiIncludeSearcher: HangataGenshiIncludeSearcher) {
    this.genshiIncludeSearcher = genshiIncludeSearcher;
  }

  public convert(input: InputForm.InputParams): CoverFormSet {
    const hasBookcover = input.hasBookcover;

    let coverIncludePages: GenshiInclude = {
      genshiId: '',
      includePages: 0,
    };

    if (hasBookcover) {
      coverIncludePages = this.genshiIncludeSearcher.searchPageInclude(
        input.bookcoverPaperId,
        input.hangataId
      );
    }

    let genshiType: GenshiType = {
      youshiSize: '',
      cuttingConvertion: 0,
    };

    if (hasBookcover) {
      const parser = new GenshiTypeParser();
      genshiType = parser.parse(coverIncludePages.genshiId);
    }

    let result: CoverFormSet = {
      hangataId: input.hangataId,
      isCoverPP: input.isPP,
      copies: input.copies,
      hasCover: input.hasBookcover,
      coverPaperId: input.bookcoverPaperId,
      coverNumOfColors: 4,
      coverIncludePages: coverIncludePages.includePages,
      coverCuttingConvertion: genshiType.cuttingConvertion,
    };

    return result;
  }
}
