export interface PrimaryHangata {
  hangataId: string;
  type: string;
}

export interface GenshiType {
  genshiId: string;
  includePages: number;
}

export interface IdGenshiIncludeTable extends PrimaryHangata {
  genshiIncludes: GenshiType[];
}

export interface IdFeeTable extends PrimaryHangata {
  value: number;
}

export interface CopyRangeFee extends PrimaryHangata {
  values: CopyUnit[];
}

export interface CopyUnit {
  copies: string;
  value: number;
}

export interface CopyPageFee extends PrimaryHangata {
  values: CopyPageUnit[];
}

export interface CopyPageUnit {
  copies: string;
  pages: string;
  value: number;
}

export enum DataType {
  BodyPrintingPlate = 'body_sappan',
  BodyPrinting = 'body_printing',
  BodyProofReadingMono = 'body_kousei_mono',
  BodyProofReadingColor = 'body_kousei_color',
  BodyIncludePages = 'body_include_pages',

  CoverPrintingPlate = 'cover_sappan',
  CoverPrintingPlatePP = 'cover_sappan_pp',
  CoverPrinting = 'cover_printing',
  CoverPP = 'cover_pp',
  CoverProofReading = 'cover_kousei',
  CoverIncludePages = 'cover_include_pages',

  HakuoshiPrintingPlate = 'hakuoshi_sappan',
  HakuoshiPress = 'hakuoshi_press',

  CoverBeltPrintingPlateTokushoku = 'coverbelt_sappan_tokushoku',
  CoverBeltPrintingPlateColor = 'coverbelt_sappan_color',
  CoverBeltPrintingTokushoku = 'coverbelt_printing_tokushoku',
  CoverBeltPrintingColor = 'coverbelt_printing_color',
  CoverBeltPP = 'coverbelt_pp',
  CoverBeltProofReading = 'coverbelt_kousei',
  CoverBeltIncludePages = 'coverbelt_include_pages',

  FrontCoverPrintingPlateTokushoku = 'frontcover_sappan_tokushoku',
  FrontCoverPrintingTokushoku = 'frontcover_printing_tokushoku',
  FrontCoverPrintingPlateColor = 'frontcover_sappan_color',
  FrontCoverPrintingColor = 'frontcover_printing_color',
  FrontCoverPP = 'frontcover_pp',
  FrontCoverProofReading = 'frontcover_kousei',
  FrontCoverIncludePages = 'frontcover_include_pages',

  MikaeshiEhonPrintingPlate = 'mikaeshi_ehon_sappan',
  MikaeshiEhonPrinting = 'mikaeshi_ehon_printing',
  MikaeshiProofReading = 'mikaeshi_kousei',
  MikaeshiIncludePages = 'mikaeshi_include_pages',

  BookBindingHeisei = 'seihon_heisei',
  BookBindingJyousei = 'seihon_jyousei',
  BookBindingEhonHeisei = 'seihon_ehon_heisei',
  BookBindingEhonJyousei = 'seihon_ehon_jyousei',
}
