import * as BSearcher from '../../table/b/unitpricesearcher';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';
import { BodyPrintingPlateCalculator } from '../calcurators/body/pringtingplate';
import { BodyPrintingCalcurator } from '../calcurators/body/printing';
import { BodyProofReadingCalculator } from '../calcurators/body/proofreading';
import { PaperCalcurator } from '../calcurators/body/paper';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';

export class BodySubTotal implements ISubTotal {
  private searchers: BodyTables;
  private formset: BodyFormSet;

  public constructor(formset: BodyFormSet, searchers: BodyTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    const plateCalc = new BodyPrintingPlateCalculator(
      this.searchers.bodyPrintingPlate
    );
    let result = plateCalc.execute(
      this.formset.hangataId,
      this.formset.isBodyColor,
      this.formset.bodyPages,
      this.formset.bodyIncludePages
    );
    subtotal.push({ id: 'bodyPrintingPlate', value: result });

    const printingCalc = new BodyPrintingCalcurator(
      this.searchers.bodyPrinting
    );
    result = printingCalc.execute(
      this.formset.hangataId,
      this.formset.isBodyColor,
      this.formset.bodyPages,
      this.formset.copies,
      this.formset.bodyIncludePages
    );
    subtotal.push({ id: 'bodyPrinting', value: result });

    const proofreadingCalc = new BodyProofReadingCalculator(
      this.searchers.bodyProofreadingMono,
      this.searchers.bodyProofreadingColor
    );
    result = proofreadingCalc.execute(
      this.formset.hangataId,
      this.formset.isBodyColor,
      this.formset.bodyPages,
      this.formset.bodyIncludePages
    );
    subtotal.push({ id: 'bodyProofreading', value: result });

    const paperCalc = new PaperCalcurator(this.searchers.bodyPaper);
    result = paperCalc.execute(
      this.formset.bodyPaperId,
      this.formset.bodyNumOfColors,
      this.formset.bodyPages,
      this.formset.copies,
      this.formset.bodyIncludePages,
      this.formset.bodyCuttingConvertion
    );
    subtotal.push({ id: 'bodyPaper', value: result });

    return subtotal;
  }
}

export interface BodyTables {
  [key: string]:
    | BSearcher.HangataTypeSearcher
    | BSearcher.CopyRangeSearcher
    | PaperInfoSearcher;
  bodyPrintingPlate: BSearcher.HangataTypeSearcher;
  bodyPrinting: BSearcher.CopyRangeSearcher;
  bodyProofreadingMono: BSearcher.HangataTypeSearcher;
  bodyProofreadingColor: BSearcher.HangataTypeSearcher;
  bodyPaper: PaperInfoSearcher;
}

export interface BodyFormSet {
  hangataId: string;
  copies: number;
  isBodyColor: boolean;
  bodyPages: number;
  bodyPaperId: number;
  bodyNumOfColors: number; //isBodyColor ? 4 : 1
  bodyIncludePages: number; //GenshiIncludePages
  bodyCuttingConvertion: number; //GenshiTYpe.cuttingConvertion
}
