import { CoverBeltBase } from './coverbeltbase';
import * as Table from '../../../table/b/structs';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class CoverBeltPrintingCalcurator extends CoverBeltBase {
  private tokushokuFeeSearcher: Searcher.HangataRangeSearcher;
  private colorFeeSearcher: Searcher.HangataRangeSearcher;
  private ppFeeSearcher: Searcher.HangataRangeSearcher;

  public constructor(
    tokushokuFeeSearcher: Searcher.HangataRangeSearcher,
    colorFeeSearcher: Searcher.HangataRangeSearcher,
    ppFeeSearcher: Searcher.HangataRangeSearcher
  ) {
    super();
    this.tokushokuFeeSearcher = tokushokuFeeSearcher;
    this.colorFeeSearcher = colorFeeSearcher;
    this.ppFeeSearcher = ppFeeSearcher;
  }

  public execute(
    hangataId: string,
    type: Table.CoverBeltType,
    isPP: boolean,
    copies: number
  ) {
    let printingPlates = this.getPrintingPlates(type, isPP);
    let target: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };

    let printingFee: number;
    if (type === Table.CoverBeltType.Colors) {
      let unit = this.colorFeeSearcher.searchUnit(target);
      printingFee = printingPlates * unit.value;
    } else {
      let unit = this.tokushokuFeeSearcher.searchUnit(target);
      if (isPP === false) {
        let tokushokuPlates = printingPlates - 1;
        printingFee = tokushokuPlates * unit.value * 1.3 + unit.value;
      } else {
        printingFee = printingPlates * unit.value * 1.3;
      }
      printingFee = parseInt(printingFee.toFixed(1));
    }

    if (isPP) {
      let unit = this.ppFeeSearcher.searchUnit(target);
      printingFee = printingFee + unit.value;
    }

    return printingFee;
  }
}
