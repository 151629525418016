import { IRegister } from './iregister';
import { Container } from '../container';
import { TYPES } from '../types';
import { PressBodyFormsetConverter } from '../../formset/converters/pressbody';
import { PressCoverFormsetConverter } from '../../formset/converters/presscover';
import { PressCoverBeltFormsetConverter } from '../../formset/converters/presscoverbelt';
import { PressFrontCoverFormsetConverter } from '../../formset/converters/pressfrontcover';
import { PressMikaeshiFormsetConverter } from '../../formset/converters/pressmikaeshi';
import { PressScaningFormsetConverter } from '../../formset/converters/pressscaning';

export class FormSetConverter implements IRegister {
  public register(container: Container) {
    container.instance(TYPES.FormSet.converter.press.body, (c: Container) => {
      const paper = c.make(TYPES.Searcher.body.includePages);
      const conv = new PressBodyFormsetConverter(paper);
      return conv;
    });

    container.instance(TYPES.FormSet.converter.press.cover, (c: Container) => {
      const paper = c.make(TYPES.Searcher.cover.includePages);
      const conv = new PressCoverFormsetConverter(paper);
      return conv;
    });

    container.instance(
      TYPES.FormSet.converter.press.coverbelt,
      (c: Container) => {
        const paper = c.make(TYPES.Searcher.coverbelt.includePages);
        const conv = new PressCoverBeltFormsetConverter(paper);
        return conv;
      }
    );

    container.instance(
      TYPES.FormSet.converter.press.frontcover,
      (c: Container) => {
        const paper = c.make(TYPES.Searcher.frontcover.includePages);
        const conv = new PressFrontCoverFormsetConverter(paper);
        return conv;
      }
    );

    container.instance(
      TYPES.FormSet.converter.press.mikaeshi,
      (c: Container) => {
        const paper = c.make(TYPES.Searcher.mikaeshi.includePages);
        const conv = new PressMikaeshiFormsetConverter(paper);
        return conv;
      }
    );

    container.instance(TYPES.FormSet.converter.press.scaning, () => {
      const conv = new PressScaningFormsetConverter();
      return conv;
    });
  }
}
