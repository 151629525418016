import { UnitPrice } from './types/unitprice';
import { Searcher } from './types/searcher';
import { Table } from './types/table';
import { FormSet } from './types/formset';
import { SubTotal } from './types/subtotal';

export const TYPES = {
  UnitPrice: UnitPrice,
  Searcher: Searcher,
  Table: Table,
  FormSet: FormSet,
  SubTotal: SubTotal,
};
