import { IRegister } from '../iregister';
import { Container } from '../../container';
import { TYPES } from '../../types';
import * as Searcher from '../../../table/b/unitpricesearcher';
import { DataType } from '../../../table/b/datafile/structs';

export class HangataRange implements IRegister {
  readonly unitpriceKeys = [
    { key: DataType.HakuoshiPress, registerKey: TYPES.Searcher.hakuoshi.press },
    { key: DataType.BodyPrinting, registerKey: TYPES.Searcher.body.printing },
    { key: DataType.CoverPrinting, registerKey: TYPES.Searcher.cover.printing },
    { key: DataType.CoverPP, registerKey: TYPES.Searcher.cover.printingPP },
    {
      key: DataType.CoverBeltPrintingTokushoku,
      registerKey: TYPES.Searcher.coverbelt.printingTokushoku,
    },
    {
      key: DataType.CoverBeltPrintingColor,
      registerKey: TYPES.Searcher.coverbelt.printingColor,
    },
    {
      key: DataType.CoverBeltPP,
      registerKey: TYPES.Searcher.coverbelt.printingPP,
    },
    {
      key: DataType.FrontCoverPrintingTokushoku,
      registerKey: TYPES.Searcher.frontcover.printingTokushoku,
    },
    {
      key: DataType.FrontCoverPrintingColor,
      registerKey: TYPES.Searcher.frontcover.printingColor,
    },
    {
      key: DataType.FrontCoverPP,
      registerKey: TYPES.Searcher.frontcover.printingPP,
    },
    {
      key: DataType.MikaeshiEhonPrinting,
      registerKey: TYPES.Searcher.mikaeshi.printing,
    },
  ];

  public register(container: Container) {
    for (const unitpriceKey of this.unitpriceKeys) {
      container.singleton(unitpriceKey.registerKey, (c: Container) => {
        const unitprices = c.make(TYPES.UnitPrice.table.b) as Map<
          string,
          any[]
        >;
        const dataKey = unitpriceKey.key;
        const unitprice = unitprices.get(dataKey);
        if (typeof unitprice === 'undefined') {
          throw new Error('Not Exists Data: ' + dataKey);
        }

        const result = new Searcher.HangataRangeSearcher(
          unitprice,
          Searcher.HangataRangeComparator
        );
        return result;
      });
    }
  }
}
