import { Range } from '../common';

export interface Genre {
  id: string;
  name: string;
}

export enum GenreID {
  PictureBook = 'picture-book',
}

export enum DistributionPlan {
  None,
  CosignmentBookStore,
  Amazon,
  BookStore,
  CosignmentWithSales,
}

export interface BasicUnitPrice extends GenreUnitPrice {
  copies: Range;
}

export interface GenreUnitPrice {
  genreId: string;
  value: number;
}

export interface GradeTypeUnitPrice extends GenreUnitPrice {
  grade: GradeType;
}

export enum GradeType {
  Simple,
  Standard,
}
