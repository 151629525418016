import { Range } from '../common';

export class RangeParser {
  public parse(text: string): Range {
    const regex = /([0-9]+)-([0-9]+)?/;
    let matches = text.match(regex);
    if (matches === null) {
      throw new Error('invalid range format');
    }

    let match_first: string = matches[1];
    if (match_first === '') {
      throw new Error('invalid range format');
    }

    let match_second: string | undefined = matches[2];

    let from = parseInt(match_first);
    let to: number | null;
    if (typeof match_second === 'undefined') {
      to = null;
    } else {
      to = parseInt(match_second);
    }

    let result: Range = {
      from: from,
      to: to,
    };
    if (result.to !== null && result.from > result.to) {
      throw new RangeError('range error');
    }

    return result;
  }
}
