import * as BSearcher from '../../table/b/unitpricesearcher';
import * as Table from '../../table/b/structs';
import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import { BookBindingCalcurator } from '../../press/calcurators/bookbinding';

export class BookBindingSubTotal implements ISubTotal {
  private searchers: BookBindingTables;
  private formset: BookBindingFormSet;

  public constructor(
    formset: BookBindingFormSet,
    searchers: BookBindingTables
  ) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let subtotal: SubTotalResult[] = [];

    const calc = new BookBindingCalcurator(
      this.searchers.hard,
      this.searchers.soft,
      this.searchers.pictureHard,
      this.searchers.pictureSoft
    );
    let result = calc.execute(
      this.formset.hangataId,
      this.formset.genreId,
      this.formset.bindingType,
      this.formset.copies,
      this.formset.pages
    );
    subtotal.push({ id: 'bookBinding', value: result });

    return subtotal;
  }
}

export interface BookBindingTables {
  [key: string]: BSearcher.HangataRangeSearcher;
  hard: BSearcher.HangataRangeSearcher;
  soft: BSearcher.HangataRangeSearcher;
  pictureHard: BSearcher.HangataRangeSearcher;
  pictureSoft: BSearcher.HangataRangeSearcher;
}

export interface BookBindingFormSet {
  hangataId: string;
  genreId: string;
  bindingType: Table.BindingType;
  copies: number;
  pages: number;
}
