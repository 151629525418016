import * as Table from './structs';
import { UnitSearcher } from '../common';

export function PromotionComparator(unit: Table.PromotionFee, target: string) {
  if (unit.id === target) {
    return true;
  }

  return false;
}

export class PromotionSearcher extends UnitSearcher<
  Table.PromotionFee,
  string
> {}
