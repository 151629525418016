import { IFormsetConverter } from './iformset';
import { PrepressFormSet } from '../../prepress/subtotal';
import * as InputForm from '../structs';
import * as Table from '../../table/a/structs';

export class PrepressFormsetConverter
  implements IFormsetConverter<PrepressFormSet> {
  public convert(input: InputForm.InputParams): PrepressFormSet {
    let frontcoverGrade = this.getGrade(input.bindingGrade);
    if (frontcoverGrade === null) {
      frontcoverGrade = Table.GradeType.Simple;
    }

    let coverGrade: Table.GradeType | null;
    if (input.hasBookcover) {
      coverGrade = this.getGrade(input.bindingGrade);
    } else {
      coverGrade = null;
    }

    const result: PrepressFormSet = {
      genreId: input.genreId,
      pages: input.pageCount,
      planingGrade: this.getGrade(input.planingGrade),
      editingGrade: this.getGrade(input.editingGrade),
      proofreadingGrade: this.getGrade(input.proofreadingGrade),
      proofreadingCount: 1, // 追加なしのため固定
      isWriting: input.isWriting,
      isRewriting: input.isRewriting,
      coverGrade: coverGrade,
      frontcoverGrade: frontcoverGrade,
      hasMikaeshi: input.hasMikaeshi,
      hasCoverBelt: input.hasCoverBelt,
    };

    return result;
  }

  public getGrade(input: string): Table.GradeType | null {
    if (input === InputForm.GradeTypeId.Simple) {
      return Table.GradeType.Simple;
    }

    if (input === InputForm.GradeTypeId.Standard) {
      return Table.GradeType.Standard;
    }

    return null;
  }
}
