import { SubTotalResult } from '../../subtotal/structs';
import { ISubTotal } from '../../subtotal/isubtotal';
import * as Body from './body';
import * as BookBinding from './bookbinding';
import * as Cover from './cover';
import * as CoverBelt from './coverbelt';
import * as FrontCover from './frontcover';
import * as Hakuoshi from './hakuoshi';
import * as Mikaeshi from './mikaeshi';
import * as Scaning from './scaning';

export class PressSubTotal implements ISubTotal {
  private searchers: PressTables;
  private formset: PressFormSet;

  public constructor(formset: PressFormSet, searchers: PressTables) {
    this.searchers = searchers;
    this.formset = formset;
  }

  public getSubTotal(): SubTotalResult[] {
    let result: SubTotalResult[] = [];

    const subtotals: ISubTotal[] = [
      new Body.BodySubTotal(this.formset.body, this.searchers.body),
      new BookBinding.BookBindingSubTotal(
        this.formset.bookbinding,
        this.searchers.bookbinding
      ),
      new Cover.CoverSubTotal(this.formset.cover, this.searchers.cover),
      new CoverBelt.CoverBeltSubTotal(
        this.formset.coverbelt,
        this.searchers.coverbelt
      ),
      new FrontCover.FrontCoverSubTotal(
        this.formset.frontcover,
        this.searchers.frontcover
      ),
      new Hakuoshi.HakuoshiSubTotal(
        this.formset.hakuoshi,
        this.searchers.hakuoshi
      ),
      new Mikaeshi.MikaeshiSubTotal(
        this.formset.mikaeshi,
        this.searchers.mikaeshi
      ),
      new Scaning.ScaningSubTotal(this.formset.scaning, this.searchers.scaning),
    ];

    for (const subtolal of subtotals) {
      let res = subtolal.getSubTotal();
      if (res.length > 0) {
        result = result.concat(res);
      }
    }

    return result;
  }
}

export interface PressTables {
  [key: string]:
    | Body.BodyTables
    | BookBinding.BookBindingTables
    | Cover.CoverTables
    | CoverBelt.CoverBeltTables
    | FrontCover.FrontCoverTables
    | Hakuoshi.HakuoshiTables
    | Mikaeshi.MikaeshiTables
    | Scaning.ScaningTables;
  body: Body.BodyTables;
  bookbinding: BookBinding.BookBindingTables;
  cover: Cover.CoverTables;
  coverbelt: CoverBelt.CoverBeltTables;
  frontcover: FrontCover.FrontCoverTables;
  hakuoshi: Hakuoshi.HakuoshiTables;
  mikaeshi: Mikaeshi.MikaeshiTables;
  scaning: Scaning.ScaningTables;
}

export interface PressFormSet {
  body: Body.BodyFormSet;
  bookbinding: BookBinding.BookBindingFormSet;
  cover: Cover.CoverFormSet;
  coverbelt: CoverBelt.CoverBeltFormSet;
  frontcover: FrontCover.FrontCoverFormSet;
  hakuoshi: Hakuoshi.HakuoshiFormSet;
  mikaeshi: Mikaeshi.MikaeshiFormSet;
  scaning: Scaning.ScaningFormset;
}
