import * as Container from './container';
import { IRegister } from './serviceproviders/iregister';
import * as SPFormset from './serviceproviders/formset';
import * as SPFormsetConverter from './serviceproviders/formsetconverter';
import * as SPTable from './serviceproviders/table';
import { Searcher } from './serviceproviders/searcher';
import { SubTotal } from './serviceproviders/subtotal';

const container = new Container.Container();
const registers: IRegister[] = [
  new SPFormset.FormSet(),
  new SPFormsetConverter.FormSetConverter(),
  new SPTable.Table(),
  new Searcher(),
  new SubTotal(),
];

for (const serviceProvider of registers) {
  serviceProvider.register(container);
}

export { container };
