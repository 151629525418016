import { IFormsetConverter } from './iformset';
import { BasicfeeFormSet } from '../../basic/subtotal';
import * as InputForm from '../structs';

export class BasicFormsetConverter
  implements IFormsetConverter<BasicfeeFormSet> {
  public convert(input: InputForm.InputParams): BasicfeeFormSet {
    let result: BasicfeeFormSet = {
      genreId: input.genreId,
      copies: input.copies,
    };

    return result;
  }
}
