import * as Searcher from '../../../table/b/unitpricesearcher';
import { BodyBase } from '././base';

export class BodyPrintingCalcurator extends BodyBase {
  private feeSearcher: Searcher.CopyRangeSearcher;

  public constructor(searcher: Searcher.CopyRangeSearcher) {
    super();
    this.feeSearcher = searcher;
  }

  public execute(
    hangataId: string,
    isColor: boolean,
    bodyPages: number,
    copies: number,
    includePages: number
  ): number {
    let target: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };
    const unit = this.feeSearcher.searchUnit(target);

    const printingPlates = this.getPrintingPlates(bodyPages, includePages);
    let monoFee = unit.value * printingPlates;
    if (isColor) {
      return monoFee * 4;
    } else {
      return monoFee;
    }
  }
}
