import * as Searcher from '../../table/a/unitpricesearcher';

export class ScaningFeeCalculator {
  private feeSearcher: Searcher.GenreSearcher;

  public constructor(feeSearcher: Searcher.GenreSearcher) {
    this.feeSearcher = feeSearcher;
  }

  public execute(genreId: string, scanCount: number): number {
    const unit = this.feeSearcher.searchUnit(genreId);
    return unit.value * scanCount;
  }
}
