export const Searcher = {
  basic: Symbol('searcher.basic'),
  planning: Symbol('searcher.planning'),
  editing: Symbol('searcher.editing'),
  proofreading: Symbol('searcher.proofreading'),
  writing: Symbol('searcher.writing'),
  rewriting: Symbol('searcher.rewriting'),
  coverdesign: Symbol('searcher.coverdesign'),
  frontcoverdesign: Symbol('searcher.frontcoverdesign'),
  mikaeshidesign: Symbol('searcher.mikaeshidesign'),
  coverbeltdesign: Symbol('searcher.coverbeltdesign'),
  dtp: Symbol('searcher.dtp'),
  body: {
    printingPlate: Symbol('searcher.body.printingplate'),
    printing: Symbol('searcher.body.printing'),
    proofreading: {
      mono: Symbol('searcher.body.proofreading.mono'),
      color: Symbol('searcher.body.proofreading.color'),
    },
    includePages: Symbol('searcher.body.includepages'),
  },
  bookbinding: {
    hard: Symbol('searcher.bookbinding.hard'),
    soft: Symbol('searcher.bookbinding.soft'),
    pictureHard: Symbol('searcher.bookbinding.picturehard'),
    pictureSoft: Symbol('searcher.bookbinding.picturesoft'),
  },
  cover: {
    printingPlate: {
      varnish: Symbol('searcher.cover.printingplate.varnish'),
      pp: Symbol('searcher.cover.printingplate.pp'),
    },
    printing: Symbol('searcher.cover.printing'),
    printingPP: Symbol('searcher.cover.printingpp'),
    proofreading: Symbol('searcher.cover.proofreading'),
    includePages: Symbol('searcher.cover.includepages'),
  },
  coverbelt: {
    printingPlate: {
      tokushoku: Symbol('searcher.coverbelt.printingplate.tokushoku'),
      color: Symbol('searcher.coverbelt.printingplate.color'),
    },
    printingTokushoku: Symbol('searcher.coverbelt.printingtokushoku'),
    printingColor: Symbol('searcher.coverbelt.printingcolor'),
    printingPP: Symbol('searcher.coverbelt.printingpp'),
    proofReading: Symbol('searcher.coverbelt.proofreading'),
    includePages: Symbol('searcher.coverbelt.includepages'),
  },
  frontcover: {
    printingPlate: {
      tokushoku: Symbol('searcher.frontcover.printingplate.tokushoku'),
      color: Symbol('searcher.frontcover.printingplate.color'),
    },
    printingTokushoku: Symbol('searcher.frontcover.printingtokushoku'),
    printingColor: Symbol('searcher.frontcover.printingcolor'),
    printingPP: Symbol('searcher.frontcover.printingpp'),
    proofReading: Symbol('searcher.frontcover.proofreading'),
    includePages: Symbol('searcher.frontcover.includepages'),
  },
  hakuoshi: {
    printingPlate: Symbol('searcher.hakuoshi.printingplate'),
    press: Symbol('searcher.hakuoshi.press'),
  },
  scaning: Symbol('searcher.scaning'),
  mikaeshi: {
    printingPlate: Symbol('searcher.mikaeshi.printingplate'),
    printing: Symbol('searcher.mikaeshi.printing'),
    proofreading: Symbol('searcher.mikaeshi.proofreading'),
    includePages: Symbol('searcher.mikaeshi.includepages'),
  },
  distribution: {
    basic: Symbol('searcher.distribution.basic'),
    cosignment: Symbol('searcher.distribution.cosignment'),
    sales: Symbol('searcher.distribution.sales'),
    amazon: Symbol('searcher.distribution.amazon'),
    promotion: Symbol('searcher.distribution.promotion'),
  },
  master: {
    paperinfo: Symbol('searcher.master.paperinfo'),
  },
};
