import { IRegister } from './iregister';
import { Container } from '../container';
import { TYPES } from '../types';
import { BasicSubTotal } from '../../basic/subtotal';
import { DistributionSubTotal } from '../../distribution/subtotal';
import * as Prepress from '../../prepress/subtotal';
import * as Press from '../../press/subtotal/presssubtotal';

export class SubTotal implements IRegister {
  public register(container: Container) {
    container.instance(TYPES.SubTotal.basic, (c: Container) => {
      const formset = c.make(TYPES.FormSet.basic);
      const searcher = c.make(TYPES.Searcher.basic);
      const subtotal = new BasicSubTotal(formset, searcher);
      return subtotal;
    });

    container.instance(TYPES.SubTotal.distribution, (c: Container) => {
      const formset = c.make(TYPES.FormSet.distribution);
      const basic = c.make(TYPES.Searcher.distribution.basic);
      const cosignment = c.make(TYPES.Searcher.distribution.cosignment);
      const sales = c.make(TYPES.Searcher.distribution.sales);
      const amazon = c.make(TYPES.Searcher.distribution.amazon);
      const promotion = c.make(TYPES.Searcher.distribution.promotion);
      const subtotal = new DistributionSubTotal(
        formset,
        basic,
        cosignment,
        sales,
        amazon,
        promotion
      );
      return subtotal;
    });

    container.instance(TYPES.SubTotal.prepress, (c: Container) => {
      const formset = c.make(
        TYPES.FormSet.prepress
      ) as Prepress.PrepressFormSet;
      const table = c.make(TYPES.Table.prepress) as Prepress.PrepressTables;
      const subtotal = new Prepress.PrepressSubTotal(formset, table);
      return subtotal;
    });

    container.instance(TYPES.SubTotal.press, (c: Container) => {
      const formset = c.make(TYPES.FormSet.press.formset) as Press.PressFormSet;
      const table = c.make(TYPES.Table.press.table) as Press.PressTables;
      const subtotal = new Press.PressSubTotal(formset, table);
      return subtotal;
    });
  }
}
