import * as Searcher from './unitpricesearcher';
import * as Table from './structs';
import { PaperInfoSearcher } from '../../table/master/unitpricesearcher/paperinfo';

export class HangataGenshiIncludeSearcher {
  private paperSeacher: PaperInfoSearcher;
  private genshiIncludePages: Table.GenshiIncludePages[];

  public constructor(
    paperSearcher: PaperInfoSearcher,
    genshiIncludePages: Table.GenshiIncludePages[]
  ) {
    this.paperSeacher = paperSearcher;
    this.genshiIncludePages = genshiIncludePages;
  }

  public searchPageInclude(
    paperId: number,
    hangataId: string
  ): Table.GenshiInclude {
    let pageIncludes: Table.GenshiIncludePages | null = null;
    for (const row of this.genshiIncludePages) {
      if (row.hangataId === hangataId) {
        pageIncludes = row;
        break;
      }
    }
    if (pageIncludes === null) {
      throw new Error('HangataGenshiInclude is not found');
    }

    const paperInfo = this.paperSeacher.searchUnit(paperId);
    const searcher = new Searcher.GenshiIncludeSearcher(
      pageIncludes.genshiIncludes,
      Searcher.GenshiIncludeComparator
    );
    const pageInclude = searcher.searchUnit(paperInfo.youshiSizeId);
    return pageInclude;
  }
}
