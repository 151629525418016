import { IFormsetConverter } from './iformset';
import { DistributionFormSet } from '../../distribution/subtotal';
import * as InputForm from '../structs';
import * as Table from '../../table/a/structs';

export class DistributionFormsetConverter
  implements IFormsetConverter<DistributionFormSet> {
  public convert(input: InputForm.InputParams): DistributionFormSet {
    const result: DistributionFormSet = {
      genreId: input.genreId,
      plan: this.getDistriPlan(input.distributionPlan),
      promotionId: input.promotionPlan,
    };

    return result;
  }

  public getDistriPlan(input: string): Table.DistributionPlan {
    if (input === InputForm.DistributionPlanId.Amazon) {
      return Table.DistributionPlan.Amazon;
    }

    if (input === InputForm.DistributionPlanId.BookStore) {
      return Table.DistributionPlan.BookStore;
    }

    if (input === InputForm.DistributionPlanId.CosignmentBookStore) {
      return Table.DistributionPlan.CosignmentBookStore;
    }

    if (input === InputForm.DistributionPlanId.CosignmentWithSales) {
      return Table.DistributionPlan.CosignmentWithSales;
    }

    return Table.DistributionPlan.None;
  }
}
