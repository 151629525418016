import * as FileData from '../structs';
import * as Table from '../../structs';
import { DataFileConverter } from '../../../datafileconverter';
import { RangeParser } from '../../../parser/range';

export class HangataCopyRangeUnitConverter
  implements DataFileConverter<Table.HangataCopyRangeUnitPrice[]> {
  readonly fileDataIdList: FileData.DataType[] = [
    FileData.DataType.HakuoshiPress,
    FileData.DataType.BodyPrinting,
    FileData.DataType.CoverPrinting,
    FileData.DataType.CoverPP,
    FileData.DataType.CoverBeltPrintingTokushoku,
    FileData.DataType.CoverBeltPrintingColor,
    FileData.DataType.CoverBeltPP,
    FileData.DataType.FrontCoverPrintingTokushoku,
    FileData.DataType.FrontCoverPrintingColor,
    FileData.DataType.FrontCoverPP,
    FileData.DataType.MikaeshiEhonPrinting,
  ];

  public hasType(type: string): boolean {
    for (const id of this.fileDataIdList) {
      if (type === id) {
        return true;
      }
    }

    return false;
  }

  public convert(parsed_data: any): Table.HangataCopyRangeUnitPrice[] {
    if (
      typeof parsed_data.type === 'undefined' ||
      this.hasType(parsed_data.type) === false
    ) {
      throw new Error('invalid type');
    } else if (
      typeof parsed_data.values === 'undefined' ||
      typeof parsed_data.values[0].copies === 'undefined'
    ) {
      throw new Error('invalid type');
    }

    let result: Table.HangataCopyRangeUnitPrice[] = [];
    let data = parsed_data as FileData.CopyRangeFee;
    const range_parser = new RangeParser();

    for (let unit of data.values) {
      let row: Table.HangataCopyRangeUnitPrice = {
        hangataId: data.hangataId,
        copies: range_parser.parse(unit.copies),
        value: unit.value,
      };
      result.push(row);
    }

    return result;
  }
}
