import * as Table from './structs';
import { UnitSearcher, RangeComparator } from '../common';
import { GenshiTypeParser } from '../parser/genshitype';

export function HangataTypeComparator(
  unit: Table.HangataTypeUnitPrice,
  target: string
): boolean {
  return unit.hangataId === target;
}

export class HangataTypeSearcher extends UnitSearcher<
  Table.HangataTypeUnitPrice,
  string
> {}

export function GenshiTypeComparator(
  unit: Table.Genshi,
  target: string
): boolean {
  return unit.id === target;
}

export class GenshitypeSearcher extends UnitSearcher<Table.Genshi, string> {}

export function GenshiIncludeComparator(
  unit: Table.GenshiInclude,
  target: string
): boolean {
  const parser = new GenshiTypeParser();
  const genshi = parser.parse(unit.genshiId);
  return genshi.youshiSize === target;
}
export class GenshiIncludeSearcher extends UnitSearcher<
  Table.GenshiInclude,
  string
> {}

export interface CopyRangeCompareSet {
  hangataId: string;
  copies: number;
}

export class CopyRangeSearcher extends UnitSearcher<
  Table.HangataCopyRangeUnitPrice,
  CopyRangeCompareSet
> {}

export function HangataRangeComparator(
  unit: Table.HangataCopyRangeUnitPrice,
  target: CopyRangeCompareSet
): boolean {
  return (
    HangataTypeComparator(unit, target.hangataId) &&
    RangeComparator(unit.copies, target.copies)
  );
}

export class HangataRangeSearcher extends UnitSearcher<
  Table.HangataCopyRangeUnitPrice,
  CopyRangeCompareSet
> {}

export interface CopyPageRangeCompareSet extends CopyRangeCompareSet {
  pages: number;
}

export function HangataCopyPageComparator(
  unit: Table.HangataCopyPageUnitPrice,
  target: CopyPageRangeCompareSet
): boolean {
  return (
    HangataTypeComparator(unit, target.hangataId) &&
    RangeComparator(unit.copies, target.copies) &&
    RangeComparator(unit.pages, target.pages)
  );
}

export class HangataCopyPageSearcher extends UnitSearcher<
  Table.HangataCopyPageUnitPrice,
  CopyPageRangeCompareSet
> {}
