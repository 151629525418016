import { GenreSearcher } from '../table/a/unitpricesearcher';
import { PromotionSearcher } from '../table/e/unitpricesearcher';
import * as Table from '../table/a/structs';
import * as Calc from './calcurator';
import { SubTotalResult } from '../subtotal/structs';
import { ISubTotal } from '../subtotal/isubtotal';

export class DistributionSubTotal implements ISubTotal {
  private basicfee: GenreSearcher;
  private cosignmentfee: GenreSearcher;
  private promotionfee: PromotionSearcher;
  private salesfee: GenreSearcher;
  private amazonfee: GenreSearcher;
  private formset: DistributionFormSet;

  public constructor(
    formset: DistributionFormSet,
    basicfee: GenreSearcher,
    cosignmentfee: GenreSearcher,
    salesfee: GenreSearcher,
    amazonfee: GenreSearcher,
    promotionfee: PromotionSearcher
  ) {
    this.formset = formset;
    this.basicfee = basicfee;
    this.cosignmentfee = cosignmentfee;
    this.salesfee = salesfee;
    this.amazonfee = amazonfee;
    this.promotionfee = promotionfee;
  }

  public getSubTotal(): SubTotalResult[] {
    let result: SubTotalResult[] = [];

    if (this.formset.plan !== Table.DistributionPlan.None) {
      const calc = new Calc.DistributionCalcurator(
        this.basicfee,
        this.cosignmentfee,
        this.salesfee,
        this.amazonfee
      );
      const distrifee = calc.execute(this.formset.genreId, this.formset.plan);
      result.push({ id: 'distribution', value: distrifee });
    }

    if (this.formset.promotionId !== '') {
      const promoCalc = new Calc.PromotionCalcurator(this.promotionfee);
      const promoFee = promoCalc.execute(this.formset.promotionId);
      result.push({ id: 'promotion', value: promoFee });
    }

    return result;
  }
}

export interface DistributionFormSet {
  genreId: string;
  plan: Table.DistributionPlan;
  promotionId: string;
}
