import * as Table from './structs';
import { UnitSearcher, RangeComparator } from '../common';

export class GradeTypeSearcher extends UnitSearcher<
  Table.GradeTypeUnitPrice,
  GradeCompareSet
> {}

export class GenreSearcher extends UnitSearcher<Table.GenreUnitPrice, string> {}

export function GenreComparetor(
  unit: Table.GenreUnitPrice,
  target: string
): boolean {
  return unit.genreId === target;
}

export interface GenreRangeCompareSet {
  genreId: string;
  copies: number;
}

export function GenreRangeComparetor(
  unit: Table.BasicUnitPrice,
  target: GenreRangeCompareSet
): boolean {
  return (
    GenreComparetor(unit, target.genreId) &&
    RangeComparator(unit.copies, target.copies)
  );
}

export class GenreRangeSearcher extends UnitSearcher<
  Table.BasicUnitPrice,
  GenreRangeCompareSet
> {}

export interface GradeCompareSet {
  genreId: string;
  grade: Table.GradeType;
}

export function GradeComparator(
  unit: Table.GradeTypeUnitPrice,
  target: GradeCompareSet
): boolean {
  return GenreComparetor(unit, target.genreId) && unit.grade === target.grade;
}
