import { IFormsetConverter } from './iformset';
import { HakuoshiFormSet } from '../../press/subtotal/hakuoshi';
import * as InputForm from '../structs';

export class PressHakuoshiFormsetConverter
  implements IFormsetConverter<HakuoshiFormSet> {
  public convert(input: InputForm.InputParams): HakuoshiFormSet {
    let result: HakuoshiFormSet = {
      hangataId: input.hangataId,
      copies: input.copies,
      isHakuoshi: input.isHakuoshi,
    };

    return result;
  }
}
