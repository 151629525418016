export class MikaeshiBase {
  public getPrintingPlates(isPictureBook: boolean): number {
    let plates: number;

    if (isPictureBook) {
      // 特色 1
      plates = 1;
    } else {
      // 絵本以外はやらない
      plates = 0;
    }

    return plates;
  }
}
