import { CoverBeltType } from '../../../table/b/structs';

export class CoverBeltBase {
  public getPrintingPlates(type: CoverBeltType, isPP: boolean): number {
    let base = 0;
    switch (type) {
      case CoverBeltType.Colors:
        base = 4;
        break;
      case CoverBeltType.Tokushoku2:
        base = 2;
        break;
      default:
        throw new Error('Invalid Type');
    }

    if (isPP) {
      return base;
    } else {
      return base + 1;
    }
  }
}
