import { MikaeshiBase } from './mikaeshibase';
import * as Searcher from '../../../table/b/unitpricesearcher';

export class MikaeshiPrintingCalcurator extends MikaeshiBase {
  private tokushokuFeeSearcher: Searcher.HangataRangeSearcher;

  public constructor(tokushokuFeeSearcher: Searcher.HangataRangeSearcher) {
    super();
    this.tokushokuFeeSearcher = tokushokuFeeSearcher;
  }

  public execute(hangataId: string, isPictureBook: boolean, copies: number) {
    let printingPlates = this.getPrintingPlates(isPictureBook);
    if (printingPlates === 0) {
      return 0;
    }

    let target: Searcher.CopyRangeCompareSet = {
      hangataId: hangataId,
      copies: copies,
    };

    let printingFee: number;
    const unit = this.tokushokuFeeSearcher.searchUnit(target);
    printingFee = printingPlates * unit.value * 1.3;
    printingFee = parseInt(printingFee.toFixed(1));

    return printingFee;
  }
}
