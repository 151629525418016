import { IFormsetConverter } from './iformset';
import { FrontCoverFormSet } from '../../press/subtotal/frontcover';
import * as InputForm from '../structs';
import { HangataGenshiIncludeSearcher } from '../../table/b/hangatagenshiincludesearcher';
import { GenshiTypeParser } from '../../table/parser/genshitype';
import { GenreID } from '../../table/a/structs';

export class PressFrontCoverFormsetConverter
  implements IFormsetConverter<FrontCoverFormSet> {
  private genshiIncludeSearcher: HangataGenshiIncludeSearcher;

  public constructor(genshiIncludeSearcher: HangataGenshiIncludeSearcher) {
    this.genshiIncludeSearcher = genshiIncludeSearcher;
  }

  public convert(input: InputForm.InputParams): FrontCoverFormSet {
    const frontCoverPaperId = input.frontcoverPaperId;
    const frontCoverIncludePages = this.genshiIncludeSearcher.searchPageInclude(
      frontCoverPaperId,
      input.hangataId
    );
    const hasCover = input.hasBookcover;

    const parser = new GenshiTypeParser();
    const genshiType = parser.parse(frontCoverIncludePages.genshiId);

    let numOfColors: number;
    if (input.genreId === GenreID.PictureBook) {
      numOfColors = 4;
    } else if (hasCover) {
      numOfColors = 1;
    } else {
      numOfColors = 4;
    }

    let result: FrontCoverFormSet = {
      hangataId: input.hangataId,
      genreId: input.genreId,
      copies: input.copies,
      hasCover: hasCover,
      isFrontCoverPP: input.isPP,
      frontCoverPaperId: frontCoverPaperId,
      frontCoverNumOfColors: numOfColors,
      frontCoverIncludePages: frontCoverIncludePages.includePages,
      frontCoverCuttingConvertion: genshiType.cuttingConvertion,
    };

    return result;
  }
}
