import * as Processer from '../table/a/unitpricesearcher';

export class Calcurator {
  private searcher: Processer.GenreRangeSearcher;

  public constructor(searcher: Processer.GenreRangeSearcher) {
    this.searcher = searcher;
  }

  public execute(genreId: string, copies: number): number {
    let target: Processer.GenreRangeCompareSet = {
      genreId: genreId,
      copies: copies,
    };
    let unit = this.searcher.searchUnit(target);
    return unit.value;
  }
}
