export const Table = {
  prepress: Symbol('table.prepress'),
  press: {
    table: Symbol('table.press.table'),
    body: Symbol('table.press.body'),
    bookbinding: Symbol('table.press.bookbinding'),
    cover: Symbol('table.press.cover'),
    coverbelt: Symbol('table.press.coverbelt'),
    frontcover: Symbol('table.press.frontcover'),
    hakuoshi: Symbol('table.press.hakuoshi'),
    mikaeshi: Symbol('table.press.mikaeshi'),
    scaning: Symbol('table.press.scaning'),
  },
};
