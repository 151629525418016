import * as Searcher from '../../../table/b/unitpricesearcher';
import { BodyBase } from './base';

export class BodyPrintingPlateCalculator extends BodyBase {
  private feeSearcher: Searcher.HangataTypeSearcher;

  public constructor(feeSearcher: Searcher.HangataTypeSearcher) {
    super();
    this.feeSearcher = feeSearcher;
  }

  public execute(
    hangataId: string,
    isColor: boolean,
    bodyPages: number,
    includePages: number
  ): number {
    const unit = this.feeSearcher.searchUnit(hangataId);

    const printingPlates = this.getPrintingPlates(bodyPages, includePages);
    let monoFee = unit.value * printingPlates;
    if (isColor) {
      return monoFee * 4;
    } else {
      return monoFee;
    }
  }
}
